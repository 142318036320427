import React from 'react'
import { useTranslation } from 'react-i18next'

import { tabs } from '../../constants/navlinks.constant'

import { TabsContainer, Tab } from './admin-page.styles'

interface IProps {
  selectedTab: string,
  onSelect: (tab: string) => void
}

const Tabs: React.FC<IProps> = ({ selectedTab, onSelect }) => {
  const { t } = useTranslation()

  return (
    <TabsContainer>
      {
        tabs.map(tab =>
          <Tab key={tab} isSelected={selectedTab === tab} onClick={() => onSelect(tab)}>
            {t(`navLinks.${tab}`)}
          </Tab>)
      }
    </TabsContainer>
  )
}

export default Tabs