import React from 'react'
import { useTranslation } from 'react-i18next';

import XIcon from '../../assets/icons/x.svg'

import { HeaderContainer, Item, TabButton } from './index.styles';
import { headerOptions } from '../../constants/navlinks.constant';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
  onClose: () => void;
}

const Header: React.FC<IProps> = ({ selectedTab, setSelectedTab, onClose }) => {
  const { t } = useTranslation()

  return (
    <HeaderContainer>
      <Item full>
        {headerOptions.map((option) => (
          <TabButton
            key={option}
            selected={selectedTab === option}
            onClick={() => setSelectedTab(option)}>
            {t(option)}
          </TabButton>
        ))}
      </Item>

      <Item onClick={onClose}>
        <img src={XIcon} alt="close icon" />
      </Item>
    </HeaderContainer >
  )
}

export default Header