import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

import ImageGrid from '../insfrastructureProjects/image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';

import { getProject } from '../../requests/snp.request';
import {
  createObject,
  updateObject
} from '../../requests/supervisor.request';

import { IProject } from '../../interfaces/snp.interface';
import {
  FormGroup,
  Wrapper,
  FormBlock
} from './admin-page.styles';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';
import FieldsComponent from '../insfrastructureProjects/fields.components';
import FormFooter from '../insfrastructureProjects/footer.component';

const initialFormData = {
  totalSum: 0
}

const skipList = [
  'nameEn',
  'additionalFields',
  'files',
]

const errMsg: any = {
  nameRu: 'projectName',
  nameKz: 'projectName',
  projectPowerRu: 'projectPower',
  projectPowerKz: 'projectPower',
  shortDescriptionKz: 'shortDescription',
  shortDescriptionRu: 'shortDescription',
  expectedEffectKz: 'expectedEffect',
  expectedEffectRu: 'expectedEffect',
}

const InvestmentForm = () => {
  const { i18n: { language } } = useTranslation()
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleSave = (values: IProject) => {
    setErrors({});
    const data: any = {
      ...values,
      additionalFields: { ...values.additionalFields, ...formData.additionalFields },
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key])) {
        setErrors((prev: any) => ({ ...prev, [errMsg[key] || key]: true }));
        toast.error(t(`errors.${errMsg[key] || key}`, language));
        return;
      }
    }

    if (formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, files: true }));
      toast.error(t(`errors.files`, language));
      return;
    }

    updateObject('SNP_INVESTMENT', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('SNP_INVESTMENT', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('SNP_INVESTMENT', val).then(res => {
        navigate(`/admin/${val}/snp_investment/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  useEffect(() => {
    loadForm();
  }, [objectId, loadForm])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`snp_investment.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`snp_investment.name`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['projectName'] ? 'error' : ''}
        />
      </FormGroup>

      <FormBlock type='white'>
        <FormGroup>
          <label className='required' htmlFor={`realizationYear`}>{t(`snp_investment.realizationYear`, lang)}</label>
          <Field
            id={`realizationYear`}
            name={`realizationYear`}
            placeholder={t(`snp_investment.realizationYear`, lang)}
            as="input"
            type="number"
            className={errors['realizationYear'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`projectPower${lang}`}>{t(`snp_investment.projectPower`, lang)}</label>
          <Field
            id={`projectPower${lang}`}
            name={`projectPower${lang}`}
            placeholder={t(`snp_investment.projectPower`, lang)}
            onChange={(e: any) => setFieldValue(`projectPower${lang}`, lowerAndTrim(e.target.value))}
            className={errors['projectPower'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`investmentFund`}>{t(`snp_investment.investmentFund`, lang)}</label>
          <Field
            id={`investmentFund`}
            name={`investmentFund`}
            placeholder={t(`snp_investment.investmentFund`, lang)}
            as={'input'}
            type={'number'}
            step={'0.001'}
            className={errors['investmentFund'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`workPlaceNumber`}>{t(`snp_investment.workPlaceNumber`, lang)}</label>
          <Field
            id={`workPlaceNumber`}
            name={`workPlaceNumber`}
            placeholder={t(`snp_investment.workPlaceNumber`, lang)}
            as={'input'}
            type={'number'}
            step={'0.001'}
            className={errors['workPlaceNumber'] ? 'error' : ''}
          />
        </FormGroup>
      </FormBlock>

      <FormBlock type='white'>
        <FormGroup>
          <label className='required' htmlFor={`shortDescription${lang}`}>{t(`snp_investment.shortDescription`, lang)}</label>
          <Field
            id={`shortDescription${lang}`}
            name={`shortDescription${lang}`}
            placeholder={t(`snp_investment.shortDescription`, lang)}
            onChange={(e: any) => setFieldValue(`shortDescription${lang}`, lowerAndTrim(e.target.value))}
            as="textarea"
            rows={5}
            className={errors['shortDescription'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`expectedEffect${lang}`}>{t(`snp_investment.expectedEffect`, lang)}</label>
          <Field
            id={`expectedEffect${lang}`}
            name={`expectedEffect${lang}`}
            placeholder={t(`snp_investment.expectedEffect`, lang)}
            onChange={(e: any) => setFieldValue(`expectedEffect${lang}`, lowerAndTrim(e.target.value))}
            className={errors['expectedEffect'] ? 'error' : ''}
          />
        </FormGroup>
      </FormBlock>

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='investment'
        onSave={handleSave}
      />
    </>
  }

  return (
    <Wrapper>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='SNP_INVESTMENT' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'SNP_INVESTMENT'}
        images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default InvestmentForm