import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #F5F5F5;
  border-radius: 0.625rem;
  padding: 0.625rem;
`

export const WhiteContainer = styled.div`

`

export const ModalContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 9999999999999;

  .input-file {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  .input-file-text {
    padding: 0 10px;
    line-height: 40px;
    text-align: left;
    height: 40px;
    display: block;
    float: left;
    box-sizing: border-box;
    width: 200px;
    border-radius: 6px 0px 0 6px;
    border: 1px solid #ddd;
  }

  .input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 0 4px 4px 0;
    background-color: #4F7FAE;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
  }

  .input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
     
  .input-file input[type=file]:focus + .input-file-btn {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }  
     
  .input-file:hover .input-file-btn {
    background-color: #7ea7cf;
  }

  .input-file:active .input-file-btn {
    background-color: #3e648a;
  }
     
  .input-file input[type=file]:disabled + .input-file-btn {
    background-color: #eee;
  }
    
  .upload-button {
    background-color: #28a745; 
    color: #fff; 
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
    
  .upload-button:hover {
    background-color: #1e7e34; 
  }

  input[type=date] {
    border-radius: 5px;
  }
  
  .buttons{
    display: flex;
    gap: 0.625rem;
    padding-top: 0.625rem;
    justify-content: center;
  }

  `;

export const ModalContent = styled.div<{ isFileUpload?: boolean, hasBg?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ hasBg }) => hasBg ? '#fff' : 'transparent'};
  padding: 1.25rem;
  border-radius: 0.3125rem;
  max-height: calc(100% - 100px);
  max-width: 100vw;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    ${({hasBg}) => !hasBg && css`
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.3125rem;
    `}
  }

  ${(isFileUpload) => isFileUpload && css`
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  `}

  .deleteModalContainer{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 0.625rem;
    align-items: center;
  }
  
`