import Icon1 from '../assets/icons/navlinks/navlink1.svg'
import Icon2 from '../assets/icons/navlinks/navlink2.svg'
import Icon3 from '../assets/icons/navlinks/navlink3.svg'
import Icon4 from '../assets/icons/navlinks/navlink4.svg'

export const navLinks: { [key: string]: string }[] = [
  {
    name: 'infrastructure',
    icon: Icon1,
  },
  {
    name: 'agricultural_producers',
    icon: Icon2,
  },
  {
    name: 'village_improvement',
    icon: Icon3,
  },
  {
    name: 'investments',
    icon: Icon4,
  },
]

export const reportTypes: string[] = [
  'education',
  'health',
  'culture-sport',
  'water-supply',
  'gas-supply',
  'internet-communication',
  'access-road',
  'village-road',
  'lighting',
]

export const sections: string[] = [
  'snp',
  'analytics',
  'sections',
]

export const tabs: string[] = [
  'general_info',
  // 'infrastructure',
  // 'agricultural_producers',
  // 'village_improvement',
  // 'investments',
  // 'additional_info'
]

export const headerOptions = ['snp', 'analytics', 'digital-passport', 'snp-info'];