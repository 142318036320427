import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';
import 'react-toastify/dist/ReactToastify.css';
import ZoomableImageModal from './zoomable-image.component';
import { IProject } from '../../interfaces/snp.interface';
import Documentation from '../reportComponents/documentation.component';
import AdditionalFields from '../reportComponents/additional-fields.component';
import ImageGrid from '../reportComponents/image-grid.component';
import { checkVal, formatDate, getLang } from '../../utils/helpers.utils';

export const getImageUrl = (imageId: number) => {
  return `/api/public/image/load?imageId=${imageId}`;
}

export const transformList = (list: any) => {
  const transformedList = list.map((item: any) => {
    const { files, ...rest } = item;
    if (!files) return item;

    const images = files.reduce((result: any, file: any) => {
      const { date, id, fileType } = file;
      const group = result.find((group: any) => group.date === date);
      if (group) {
        group.imageIds.push(id);
      } else {
        result.push({ fileType, date, imageIds: [id] });
      }
      return result;
    }, []);
    return { ...rest, images };
  });

  return transformedList;
}

const Education = ({ kato }: { kato: number }) => {
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);

  const openImageModal = (imageId: number, images: any) => {
    setIsOpen(true);
    setSelectedImage(imageId);
    const imageIdList = images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []);
    setCurrentImageList(imageIdList);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);

    if (snpData) {
      getProjectList('education', snpData.kato)
        .then((res) => {
          setProjectList(transformList(res));
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }

  }, [])

  useEffect(() => {
    getProjectList('education', kato)
      .then((res) => {
        setProjectList(transformList(res));
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading">{project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}</div>
            <div className="items">
              {
                (project.constructionYear || project.quality) &&
                <div className="item">
                  {
                    project.constructionYear && <div>
                      {t('form.constructionYear')}:
                      <b>{project.constructionYear}</b>
                    </div>
                  }
                  <div className="divider" />
                </div>
              }
              {
                (project.factInfo || project.power) &&
                <div className="item">

                  {
                    project.power && <div>
                      {t('form.power')}:
                      <b>{project.power}</b>
                      {project.isSchool ? t('form.students') : t('children')}
                    </div>
                  }

                  <div className="divider" />

                  {
                    project.factInfo && <div>
                      {t('form.factInfo')}:
                      <b>{project.factInfo}</b>
                      {project.isSchool ? t('form.students') : t('children')}
                    </div>
                  }

                </div>
              }
            </div>

            {
              project.sportSections && Object.keys(project.sportSections).length > 0 && <div className="block">
                <div className="subheading">{t('form.sportSections')}</div>
                <ul>
                  {Object.keys(project.sportSections).map((section) =>
                    <li key={section}>
                      {t(`sport.${section}`)}<b>: {project.sportSections[section]}</b>
                    </li>)}
                </ul>
              </div>
            }

            {
              checkVal(project.requirements)
              && <div className="text">
                {project.requirements !== 'NOT_NEEDED' && `${t('form.requirements')} `}
                {project.requirements !== 'NOT_NEEDED' ? t(`requirementsOptions.${project.requirements}`).toLowerCase() : t(`requirementsOptions.${project.requirements}`)}
                {
                  checkVal(project[`requirements${getLang()}`]) && project.requirements !== 'NOT_NEEDED'
                  && <>: {project[`requirements${getLang()}`]}</>
                }
              </div>
            }

            {
              project.requirements
              && project.requirements !== 'NOT_NEEDED'
              && project.lastMajorRenovation
              && checkVal(project.lastMajorRenovation)
              && <div className="text">{t('requirementsOptions.lastMajorRenovation')}: <b> {formatDate(project.lastMajorRenovation)}</b></div>
            }

            {project.requirements && project.requirements !== 'NOT_NEEDED' && <Documentation project={project} />}

            <AdditionalFields project={project} />

            {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} />}

          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
        />
      )}
    </>
  )
}

export default Education