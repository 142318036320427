import { DiagramType } from "../interfaces/snp.interface"

const kostanayDistricts: {
  kato: number,
  coordinates: [number, number],
  NAME_EN: string
}[] = [
    { kato: 393200000, coordinates: [64.5, 53.0], NAME_EN: 'Altynsarin' },
    { kato: 393400000, coordinates: [65.2, 50.4], NAME_EN: 'Amangeldi' },
    { kato: 393600000, coordinates: [64.0, 52.2], NAME_EN: 'Auliekol' },
    { kato: 396400000, coordinates: [62.7, 52.7], NAME_EN: 'Taranov' },
    { kato: 394000000, coordinates: [61.6, 52.6], NAME_EN: 'Denisov' },
    { kato: 394200000, coordinates: [63.6, 49.7], NAME_EN: 'Zhangeldi' },
    { kato: 394400000, coordinates: [60.9, 51.9], NAME_EN: 'Zhitikara' },
    { kato: 394800000, coordinates: [62.3, 51.6], NAME_EN: 'Kamysty' },
    { kato: 395000000, coordinates: [61.9, 53.4], NAME_EN: 'Karabalyk' },
    { kato: 395200000, coordinates: [65.4, 52.5], NAME_EN: 'Karasu' },
    { kato: 395400000, coordinates: [63.6, 53.4], NAME_EN: 'Kostanay' },
    { kato: 395600000, coordinates: [64.2, 53.9], NAME_EN: 'Mendikara' },
    { kato: 395800000, coordinates: [64.0, 51.3], NAME_EN: 'Nauryzym' },
    { kato: 396200000, coordinates: [65.6, 53.4], NAME_EN: 'Sarykol' },
    { kato: 396600000, coordinates: [65.4, 54.1], NAME_EN: 'Uzynkol' },
    { kato: 396800000, coordinates: [62.9, 53.8], NAME_EN: 'Federov' },
    { kato: 391600000, coordinates: [66.8, 50.1], NAME_EN: 'Arkalyk c.a.' },
  ]

const aktobeDistricts = [
  { kato: 153400000, coordinates: [61.1, 50.1] }, //Айтеке би
  { kato: 153220000, coordinates: [57.3, 49.9] }, // Алга
  { kato: 153600000, coordinates: [56.4, 47.4] }, // Байганин
  { kato: 156800000, coordinates: [62.1, 48.6] }, // Иргиз
  { kato: 154000000, coordinates: [58.2, 50.8] }, // Каргалы 
  { kato: 154600000, coordinates: [56.9, 50.7] }, // Мартук
  { kato: 154800000, coordinates: [57.4, 49.3] }, // Мугалжар
  { kato: 155600000, coordinates: [56.5, 49.1] }, // Темир 
  { kato: 155200000, coordinates: [54.7, 49.1] }, // Ойыл 
  { kato: 154200000, coordinates: [55.7, 50.2] }, // Кобда
  { kato: 156000000, coordinates: [58.5, 50.2] }, // Хромтау
  { kato: 156400000, coordinates: [59.5, 47.8] }, // Шалкар
]

const diagramTypes: DiagramType[] = [
  'population',
  'nationality',
  'employment',
  'education',
  'healthcare',
  'studying',
  'road',
  'harvest',
  'lifestock',
  'finance',
]

const options = [
  { label: 'GOOD', value: 'GOOD' },
  { label: 'SATISFACTORY', value: 'SATISFACTORY' },
  { label: 'BAD', value: 'BAD' },
]

const roadCoverOptions = [
  { label: 'PAVED', value: 'PAVED' },
  { label: 'GROUND', value: 'GROUND' },
  { label: 'GRAVEL', value: 'GRAVEL' },
]

const documentationStatusOptions = [
  { label: 'HAVE', value: 'HAVE' },
  { label: 'IN_PROCESS', value: 'IN_PROCESS' },
  { label: 'NOT_HAVE', value: 'NOT_HAVE' },
]

const educationOptions = [
  { label: 'school', value: 'true' },
  { label: 'kindergarten', value: 'false' },
]

const requirementsOptions = [
  'NOT_NEEDED',
  'MAJOR_RENOVATION',
  'CURRENT_RENOVATION',
  'RECONSTRUCTION'
]

const sportSectionsList: string[] = [
  "basketball",
  "boxing",
  "volleyball",
  "freestyle-wrestling",
  "greco-roman-wrestling",
  "judo",
  "kazakh-wrestling",
  "karate",
  "athletics",
  "skiing",
  "ping-pong",
  "swimming",
  "taekwondo",
  "dancing",
  "9kumalak",
  "weight-lifting",
  "figure-skating",
  "football",
  "hockey",
  "gymnastics",
  "chess"
];

const sportSectionOptions = sportSectionsList.map(section => ({ label: section, value: section }));

const aktobeDistrictsWithNames = [
  { kato: 153400000, coordinates: [61.1, 50.1], nameRu: "Айтеке би" }, //Айтеке би
  { kato: 153220000, coordinates: [57.3, 49.9], nameRu: "Алга" }, // Алга
  { kato: 153600000, coordinates: [56.4, 47.4], nameRu: "Байганин" }, // Байганин
  { kato: 156800000, coordinates: [62.1, 48.6], nameRu: "Иргиз" }, // Иргиз
  { kato: 154000000, coordinates: [58.2, 50.8], nameRu: "Каргалы" }, // Каргалы
  { kato: 154600000, coordinates: [56.9, 50.7], nameRu: "Мартук" }, // Мартук
  { kato: 154800000, coordinates: [57.9, 49.3], nameRu: "Мугалжар" }, // Мугалжар
  { kato: 155600000, coordinates: [56.5, 49.1], nameRu: "Темир" }, // Темир
  { kato: 155200000, coordinates: [54.7, 49.1], nameRu: "Ойыл" }, // Ойыл
  { kato: 154200000, coordinates: [55.7, 50.2], nameRu: "Кобда" }, // Кобда
  { kato: 156000000, coordinates: [58.5, 50.2], nameRu: "Хромтау" }, // Хромтау
  { kato: 156400000, coordinates: [59.5, 47.4], nameRu: "Шалкар" }, // Шалкар
];

const irgizDistrictsWithNames = [
  { kato: 156837000, coordinates: [63.12729181250001, 47.89945525030757], nameRu: "Жайсанбайский" }, //Жайсанбайский
  { kato: 156845000, coordinates: [62.03964532812501, 48.334028909993684], nameRu: "Тауипский" }, // Тауипский
  { kato: 156843000, coordinates: [62.49008478125001, 48.728708730877855], nameRu: "Нуринский" }, // Нуринский
  { kato: 156839000, coordinates: [60.59768976171876, 49.19037030772027], nameRu: "Кумтогайский" }, // Кумтогайский 11
  { kato: 156841000, coordinates: [60.49331964453126, 48.77760123427689], nameRu: "Кызылжарский" }, // Кызылжарский 11
  { kato: 156830000, coordinates: [61.03439630468751, 48.77036090265034], nameRu: "Иргизский" }, // Иргизский
  { kato: 156835000, coordinates: [61.49032892187501, 48.76492996868138], nameRu: "Аманкольский" }, // Аманкольский
];



export {
  kostanayDistricts,
  diagramTypes,
  options,
  roadCoverOptions,
  documentationStatusOptions,
  educationOptions,
  requirementsOptions,
  sportSectionsList,
  sportSectionOptions,
  aktobeDistricts,
  aktobeDistrictsWithNames,
  irgizDistrictsWithNames
}
