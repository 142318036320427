import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';


import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock } from './infrastructure.styles';

import { IProject } from '../../interfaces/snp.interface';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { options, requirementsOptions } from '../../constants/snp.constant';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import { documentationKeys, errMsg } from './education-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "nameKz": "",
  "nameRu": "",
  "nameEn": "",
  "constructionYear": 0,
  "factInfo": "",
  "power": 0,
  "requirements": "",
  "documentationStatus": "",
  "requiredFundSource": "",
  "requiredFundAmount": 0,
  "workStart": "",
  "workEnd": ""
}

export const skipList = [
  "nameEn",
  "requiredFund",
  "files",
  "additionalFields",
  "requirementsRu",
  "requirementsKz",
  "repairRequired",
  "lastMajorRenovation",
  ...documentationKeys
];

const WaterSupplyForm = () => {
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const { i18n: { language } } = useTranslation();

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleSave = (values: IProject) => {
    setErrors({})
    const data: any = {
      ...values,
      additionalFields: { ...values.additionalFields, ...formData.additionalFields },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {

        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (data.requirements && data.requirements !== 'NOT_NEEDED') {
      if (!checkVal(data.requirementsKz) || !checkVal(data.requirementsRu)) {
        setErrors({ ...errors, exactly: true })
        toast.error(t(`errors.exactly`, language))
        return
      }

      if (!data.lastMajorRenovation) {
        setErrors({ ...errors, lastMajorRenovation: true })
        toast.error(t(`errors.fill-field`, language))
        return
      }

      if (!checkVal(data.documentationStatus)) {
        setErrors({ ...errors, documentationStatus: true })
        toast.error(t(`errors.documentationStatus`, language))
        return
      } else if (data.documentationStatus === 'HAVE') {
        for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
          if (!checkVal(data[key])) {
            setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
            toast.error(t(`errors.${errMsg[key]}`, language))
            return
          }
        }
      }
    }

    if (formData.files && formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, files: true }))
      toast.error(t(`errors.files`, language))
      return
    }

    updateObject('water-supply', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('water-supply', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('water-supply', val).then(res => {
        navigate(`/admin/${val}/infrastructure/water-supply/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`form.structureName`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`form.structureName`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['objectName'] ? 'error' : ''}
        />
      </FormGroup>

      <FormBlock>
        <div className="grid">
          <FormGroup>
            <label className='required' htmlFor={`constructionYear`}>{t(`form.constructionYear`, lang)}</label>
            <Field
              id={`constructionYear`}
              name={`constructionYear`}
              placeholder={t(`form.constructionYear`, lang)}
              as="input"
              type="number"
              className={errors['constructionYear'] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`factInfo`}>{t(`form.factInfo`, lang)}</label>
            <Field
              id={`factInfo`}
              name={`factInfo`}
              placeholder={t(`form.factInfo`, lang)}
              className={errors['factInfo'] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`power`}>{t(`form.power`, lang)}</label>
            <Field
              id={`power`}
              name={`power`}
              placeholder={t(`form.power`, lang)}
              as="input"
              type="number"
              className={errors['power'] ? 'error' : ''}
            />
          </FormGroup>
        </div>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
          <select
            value={formData.factCondition}
            onChange={(e) => {
              setFieldValue(`factCondition`, e.target.value);
              setFormData({ ...formData, factCondition: e.target.value })
            }}
            className={errors['factCondition'] ? 'error' : ''}
          >
            <option value="" selected hidden></option>
            {
              options.map((option) => (
                <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
              ))
            }
          </select>
        </FormGroup>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`requirements`}>{t(`requirementsOptions.required`, lang)}</label>
          <select
            name="requirements"
            id="requirements"
            onChange={(e: any) => {
              setFieldValue('requirements', e.target.value);
              setFormData({ ...formData, requirements: e.target.value })
            }}
            defaultValue={""}
            value={formData.requirements}
            className={errors['repair-required'] ? 'error' : ''}
          >
            <option value="" hidden></option>
            {requirementsOptions.map((option) => (
              <option value={option}>{t(`requirementsOptions.${option}`, lang)}</option>
            ))}
          </select>
        </FormGroup>

        {
          formData.requirements && formData.requirements !== "" && formData.requirements !== 'NOT_NEEDED' && <>
            <FormGroup>
              <label
                className='required'
                htmlFor={`requirements${lang}`}>
                {t(`requirementsOptions.exactly`, lang)}
              </label>
              <div className='exactly-required'>
                <div>
                  {t('required', lang)}:
                </div>
                <Field
                  id={`requirements${lang}`}
                  name={`requirements${lang}`}
                  placeholder={t(`form.requirements${lang}`, lang)}
                  className={errors['exactly'] ? 'error' : ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, [`requirements${lang}`]: e.target.value.toLowerCase() })
                    setFieldValue(`requirements${lang}`, e.target.value.toLowerCase())
                  }}
                />
              </div>

            </FormGroup>

            <FormGroup>
              <label className='required' htmlFor={`lastMajorRenovation`}>{t(`requirementsOptions.lastMajorRenovation`, lang)}</label>
              <Field
                id={`lastMajorRenovation`}
                name={`lastMajorRenovation`}
                placeholder={t(`requirementsOptions.lastMajorRenovation`, lang)}
                as="input"
                type="date"
                value={formData.lastMajorRenovation ? formData.lastMajorRenovation.split('T')[0] : null}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormData({ ...formData, lastMajorRenovation: e.target.value })
                  setFieldValue('lastMajorRenovation', e.target.value)
                }}
                className={errors.lastMajorRenovation ? 'error' : ''}
              />
            </FormGroup>
          </>
        }
      </FormBlock>

      {
        formData.requirements && formData.requirements !== ""
        && formData.requirements !== 'NOT_NEEDED'
        && <DocumentationForm
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          type='education'
          onSave={handleSave}
          errors={errors}
        />
      }

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='water-supply'
        onSave={handleSave}
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='water-supply' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'water-supply'} images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default WaterSupplyForm