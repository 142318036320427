import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

import ImageGrid from '../insfrastructureProjects/image-grid.component';

import { getProject } from '../../requests/snp.request';
import { createObject, updateObject } from '../../requests/supervisor.request';

import { IProject } from '../../interfaces/snp.interface';

import { FormGroup, Wrapper } from './admin-page.styles';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { checkVal, getLang, lowerAndTrim, t } from '../../utils/helpers.utils';
import FormFooter from '../insfrastructureProjects/footer.component';
import { documentationKeys, errMsg } from '../insfrastructureProjects/education-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';

const initialFormData = {
  totalSum: 0
}

const checkList = [
  "nameKz",
  "nameRu",
  "documentationStatus"
]

const VillageImprovementForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleSave = (values: IProject) => {
    setErrors({})
    const data: any = {
      ...values,
      additionalFields: { ...values.additionalFields, ...formData.additionalFields },
    }

    for (const keys of checkList) {
      if (!checkVal(data[keys])) {
        setErrors((prev: any) => ({ ...prev, [errMsg[keys]]: true }))
        toast.error(t(`errors.${errMsg[keys]}`, language))
        return
      }
    }

    if (["HAVE", "IN_PROCESS"].includes(data.documentationStatus)) {
      for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
        if (!checkVal(data[key])) {
          setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
          toast.error(t(`errors.${errMsg[key]}`, language))
          return
        }
      }
    }

    if (formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, files: true }))
      toast.error(t(`errors.files`, language))
      return
    }

    updateObject('SNP_IMPROVEMENT', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('SNP_IMPROVEMENT', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('SNP_IMPROVEMENT', val).then(res => {
        navigate(`/admin/${val}/snp_improvement/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup >
        <label className='required' htmlFor={`name${lang}`}>{t(`snp_improvement.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`snp_improvement.name`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['objectName'] ? 'error' : ''}
        />
      </FormGroup>

      <DocumentationForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='SNP_IMPROVEMENT'
        onSave={handleSave}
        errors={errors}
      />

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='SNP_IMPROVEMENT'
        onSave={handleSave}
      />
    </>
  }

  return (
    <Wrapper>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid-item">
                  {renderFields(getLang(), setFieldValue)}
                </div>
                <div className="grid-item">
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                </div>
                <FormFooter type='SNP_IMPROVEMENT' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'SNP_IMPROVEMENT'}
        images={images}
        lang={language}
      />
    </Wrapper >
  )
}

export default VillageImprovementForm