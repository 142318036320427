import React, { useEffect, useRef, useState } from 'react'
import Header from './header.component';

import { Filters, Item, RightMenuContainer } from './index.styles';
import { IDistrictIncomeList, IMarker, IPopulationDynamic } from '../../interfaces/snp.interface';
import { navLinks } from '../../constants/navlinks.constant';
import { useParams, useNavigate } from 'react-router-dom';
import XIcon from '../../assets/icons/x.svg'
import { getGeneralInfo } from '../../requests/snp.request';
import { useTranslation } from 'react-i18next';
import { getLang, isRegion } from '../../utils/helpers.utils';
import BasicStats from './basic-stats.component';
import { Text } from '../text.component';
import { FilterIcon } from '../../assets/icons';
import DistrictIncome from './district-income.component';
import PopulationDynamic from './population-dynamic.component';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string | null) => void;
  selectedMarker: IMarker | null;
  infoLink: string | null;
  selectedRegion: any;
}


const RightMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedMarker, infoLink, selectedRegion }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { link, kato } = useParams()
  const [basicStats, setBasicStats] = useState<any>(null)
  const [additionalStats, setAdditionalStats] = useState<any>([])
  const [districtIncome, setDistrictIncome] = useState<IDistrictIncomeList | null>(null)
  const [chartInfo, setChartInfo] = useState<any>({
    data: null,
    options: null
  })

  const closeModal = () => {
    setIsOpen(false);
    // setSelectedLink(null);
    // navigate('/');
  };

  const getColor = (year: number) => {
    if (year === currentYear) {
      return '#118F84';
    } if (year < currentYear) {
      return '#98A3A9';
    } else {
      return '#606367'
    }
  };

  const getChartInfo = (chartData: IPopulationDynamic[]) => {
    const years = chartData?.map((item: IPopulationDynamic) => item.year);
    const data: any = {
      labels: years,
      datasets: [
        {
          label: 'Динамика населения',
          data: chartData.map((item: IPopulationDynamic) => (item.value)),
          borderColor: '#98A3A9',
          backgroundColor: '#98A3A9',
          pointRadius: 5,
          pointBackgroundColor: years.map(year => getColor(year)),
          borderDash: years.map(year => year < currentYear ? [] : [5, 5]),
          segment: {
            borderDash: (ctx: any) => +ctx.p0DataIndex >= 3 ? [3, 3] : []
          },
          spanGaps: true
        },
      ],
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          position: 'top',
          ticks: {
            color: years.map(year => year === currentYear ? '#118F84' : '#98A3A9'),
            align: 'center',
            baseLine: 'middle',
            font: {
              size: years.map(year => year === currentYear ? 14 : 12),
              weight: years.map(year => year === currentYear ? 700 : 400),
            },
          },
        },
        y: {
          grid: {
            color: '#98A3A9',
            beginAtZero: false,
          },
          offset: true,
          ticks: {
            display: false,
            maxTicksLimit: 5,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: years.map(year => getColor(year)),
          formatter: function (value: any) {
            return value;
          },
          font: years.map(year => ({
            size: year === currentYear ? 14 : 12,
            weight: year === currentYear ? 700 : 400,
          })),
          textAlign: 'center',
          clamp: true,
          anchor: 'start',
          align: 'start',
        }
      },
      layout: {
        backgroundColor: '#F8F7EF',
      },
    };

    setChartInfo({ data, options });
  }

  useEffect(() => {
    if (selectedTab) {
      if (selectedTab !== 'snp') {
        // setIsOpen(true);
        setSelectedLink(null);
        kato ? navigate(`/${kato}/${selectedTab}`) : navigate(`/${selectedTab}`)
      } else {
        !selectedLink && setSelectedLink(navLinks[0].name);
        kato ? navigate(`/${kato}/snp`) : navigate('/snp')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, setSelectedLink])

  useEffect(() => {
    if (selectedLink) {
      // setIsOpen(true);
    }
  }, [selectedLink, kato])

  // useEffect(() => {
  //   link && setSelectedTab(link);
  // }, [link])

  useEffect(() => {
    if (kato || selectedMarker) {
      setIsOpen(true);
      const val = selectedMarker ? selectedMarker.kato : kato;
      if (val) {
        getGeneralInfo(+val).then((res) => {
          if (res.basicStats?.every((stat: any) => stat.previousYearVal === null || stat.currentYearVal === null || stat.increaseVal === null)) {
            setBasicStats([])
            setDistrictIncome(null)
          } else {
            const grouped = res.basicStats?.filter((item: any) => item.type !== 'ADDITIONAL_STAT').reduce((acc: any, stat: any) => {
              if (!acc[stat.type]) {
                acc[stat.type] = [];
              }
              acc[stat.type].push(stat);
              return acc;
            }, {});

            const additionalStats = res.basicStats?.filter((item: any) => item.type === 'ADDITIONAL_STAT');

            const groupedAdditionalStats = additionalStats?.reduce((acc: any, stat: any) => {
              if (!acc[stat.additionalInfo[`type${getLang()}`]]) {
                acc[stat.additionalInfo[`type${getLang()}`]] = [];
              }
              acc[stat.additionalInfo[`type${getLang()}`]].push(stat);
              return acc;
            }, {})

            setDistrictIncome(res.districtIncomeStats)
            getChartInfo(res.populationIncreaseDynamicStats)
            setBasicStats(grouped);
            setAdditionalStats(groupedAdditionalStats);
          }
        })
      }
    }
  }, [kato, selectedMarker])

  return (
    <>
      {isOpen && <RightMenuContainer>
        <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={() => null} />
        <Filters>
          <Text fontWeight={500} color='#606367' fontSize="1.125rem">
            {selectedRegion?.[`name${getLang()}`]}
          </Text>
          <div className="search-bar">
            <input type="text" />
            <FilterIcon />
          </div>
          <div className="dates">
            <Text fontWeight={500} color='#202224' fontSize="0.75rem" lineHeight='1'>
              период
            </Text>
            <input type="date" name="start" id="start" />
            <div>-</div>
            <input type="date" name="end" id="end" />
          </div>
        </Filters>
        <div className="grid">
          {basicStats && additionalStats && <BasicStats basicStats={basicStats} additionalStats={additionalStats} />}
          {districtIncome && districtIncome.totalIncomeIncrease && kato && isRegion(+kato) ? <DistrictIncome data={districtIncome} /> : basicStats && additionalStats && <BasicStats basicStats={basicStats} additionalStats={additionalStats} />}
          {chartInfo.data && chartInfo.data?.datasets?.data?.some((item: any) => item.value) && chartInfo.options ? <PopulationDynamic chartInfo={chartInfo} /> : basicStats && additionalStats && <BasicStats basicStats={basicStats} additionalStats={additionalStats} />}
        </div>
        <Item onClick={() => closeModal()} className='close-button'>
          <img src={XIcon} alt="close icon" />
        </Item>
      </RightMenuContainer >
      }
    </>
  )
}

export default RightMenu