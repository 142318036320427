import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { createObject, updateObject, createInfrastructureStreet, updateInfrastructureStreet, deleteInfrastructureStreet } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';

import { AddField } from '../../assets/icons';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from './infrastructure.styles';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import { IProject } from '../../interfaces/snp.interface';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { options } from '../../constants/snp.constant';
import { checkVal, getLang, lowerAndTrim, t } from '../../utils/helpers.utils';
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import { documentationKeys, errMsg } from './education-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { streetSkipList } from './village-road-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "totalStreetNumber": 0,
  "illuminated": 0,
}

const defaultStreet = {
  nameRu: '',
  nameKz: '',
  totalStreetLength: '',
  repairRequired: '',
  documentationStatus: '',
  requiredFundSourceRu: '',
  requiredFundSourceKz: '',
  requiredFundAmount: '',
  workStart: '',
  workEnd: '',
  files: [],
  images: [],
  id: 0,
  factCondition: '',
}

const skipList = [
  "nameEn",
  "additionalFields",
  "streetList",
  "factCondition",
]

const LightingForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>(initialFormData);
  const [isAddingStreet, setIsAddingStreet] = useState(false);
  const [newStreet, setNewStreet] = useState<any>(defaultStreet);
  const [lightingCover, setLightingCover] = useState(0);
  const [errors, setErrors] = useState<any>({});

  const addNewStreet = () => {
    setErrors({});

    const keys = Object.keys(newStreet).filter(key => !streetSkipList.includes(key));

    for (const key of keys) {
      if (!checkVal(newStreet[key]) && typeof newStreet[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-street`]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return false;
      }
    }

    if (newStreet.repairRequired === 'true' || newStreet.repairRequired === true) {
      for (const key of documentationKeys) {
        if (!checkVal(newStreet[key]) && typeof newStreet[key] !== 'boolean') {
          setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}`]: true }))
          toast.error(t(`errors.${errMsg[key]}`, language))
          return false;
        }
      }
    }

    const updatedStreets = [
      ...formData.streetList,
      {
        ...newStreet,
        totalStreetLength: +newStreet.totalStreetLength,
        repairRequired: newStreet.repairRequired === 'true' ? true : false,
      },
    ];

    const data = {
      ...formData,
      streetList: updatedStreets,
    }

    setFormData((prev: any) => data);
    updateInfrastructureStreet('LIGHTING_STREET', updatedStreets);
    setNewStreet(defaultStreet)
    setIsAddingStreet(false)

    return true
  }

  const handleSave = (values: any) => {
    setErrors({});
    updateInfrastructureStreet('LIGHTING_STREET', formData.streetList).then((res: any) => {
      const data = {
        ...values,
        additionalFields: formData.additionalFields,
        streetList: res.list,
      }

      delete data.streets;
      documentationKeys.forEach(key => delete data[key])

      const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

      for (const key of keys) {
        if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
          setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
          toast.error(t(`errors.${errMsg[key]}`, language))
          return
        }
      }

      if (data.streetList.length !== 0) {
        const list = data.streetList;
        for (const street of list) {
          const keys = Object.keys(street).filter((key: string) => !streetSkipList.includes(key));

          for (const key of keys) {
            if (!checkVal(street[key]) && typeof street[key] !== 'boolean') {
              setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-${street.id}`]: true }))
              toast.error(t(`errors.${errMsg[key]}`, language))
              return
            }
          }

          if (street.repairRequired === 'true' || street.repairRequired === true) {
            for (const key of documentationKeys) {
              if (!checkVal(street[key]) && typeof street[key] !== 'boolean') {
                setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-${street.id}`]: true }))
                toast.error(t(`errors.${errMsg[key]}`, language))
                return
              }
            }
          }

          if (street.files.length === 0) {
            setErrors((prev: any) => ({ ...prev, [`files-${street.id}`]: true }))
            toast.error(t(`errors.files`, language))
            return
          }
        }
      }

      if (isAddingStreet) {
        if (addNewStreet()) {
          updateObject('lighting', data)
            .then(() => toast.success(t(`toast.save_success`, language)))
            .catch(() => toast.error(t(`toast.save_error`, language)))
        }
      } else {
        updateObject('lighting', data)
          .then(() => toast.success(t(`toast.save_success`, language)))
          .catch(() => toast.error(t(`toast.save_error`, language)))
      }
    })
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('lighting', objectId).then(res => {
      setFormData(res.infrastructureEntity)
    })
  }

  const handleInputChange = (valueKey: string, value: string, streetId: number) => {
    setFormData({
      ...formData, streetList: formData.streetList.map((item: any) => item.id === streetId ? {
        ...item,
        [valueKey]: value,
      }
        : item
      )
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('lighting', val).then(res => {
        navigate(`/admin/${val}/infrastructure/lighting/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const createStreet = () => {
    createInfrastructureStreet('LIGHTING_STREET', formData.id).then(res => {
      setNewStreet(res);
      setIsAddingStreet(true);
    })
  }

  const formatStreetImages = (inputArray: any) => {
    const formattedObject = inputArray.reduce((result: any, item: any) => {
      if (!result[item.fileType]) {
        result[item.fileType] = [];
      }
      result[item.fileType].push(item);
      return result;
    }, {});

    return formattedObject
  }

  const handleDeleteStreet = (id: number) => {
    deleteInfrastructureStreet('LIGHTING_STREET', id).then(res => {
      getUpdatedFormData(+objectId!)
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`totalStreetNumber`}>{t(`form.totalStreetNumber`, lang)}</label>
          <Field
            id={`totalStreetNumber`}
            name={`totalStreetNumber`}
            placeholder={t(`form.totalStreetNumber`, lang)}
            as="input"
            type="number"
            min={0}
            className={errors['totalStreetNumber'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`totalStreetLength`}>{t(`form.totalStreetLength`, lang)}</label>
          <Field
            id={`totalStreetLength`}
            name={`totalStreetLength`}
            placeholder={t(`form.totalStreetLength`, lang)}
            as="input"
            type="number"
            onChange={(e: any) => {
              setFieldValue('totalStreetLength', e.target.value);
              setFormData({ ...formData, totalStreetLength: +e.target.value })
            }}
            min={0}
            step={'0.001'}
            className={errors['totalStreetLength'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`illuminated`}>{t(`form.illuminated`, lang)}</label>
          <Field
            id={`illuminated`}
            name={`illuminated`}
            placeholder={t(`form.illuminated`, lang)}
            className={errors['illuminated'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`illuminatedStreetLength`}>{t(`form.illuminatedLength`, lang)}</label>
          <Field
            id={`illuminatedStreetLength`}
            name={`illuminatedStreetLength`}
            placeholder={t(`form.illuminatedLength`, lang)}
            as="input"
            type="number"
            onChange={(e: any) => {
              setFieldValue('illuminatedStreetLength', e.target.value);
              setFormData({ ...formData, illuminatedStreetLength: +e.target.value })
            }}
            min={0}
            step={'0.001'}
            className={errors['illuminatedStreetLength'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`lightingCover`}>{t(`form.lightingCover`, lang)}</label>
          <Field
            id={`lightingCover`}
            name={`lightingCover`}
            placeholder={t(`form.lightingCover`, lang)}
            as="input"
            type="number"
            disabled
            value={lightingCover}
          />
        </FormGroup>
      </FormBlock>

      {formData.streetList && formData.streetList.length > 0 && formData.streetList.map((item: any) =>
        <FormBlock key={`${item.id}${getLang()}`} isStreet>

          <div className='delete-street' onClick={() => handleDeleteStreet(item.id)}>x</div>
          <FormGroup>
            <label className='required' htmlFor={`streetList[${item.id}].streetName`}>{t(`form.streetName`, lang)}</label>
            <input
              id={`streets.${item.id}.streetName`}
              placeholder={t(`form.streetName`, lang)}
              value={item[`name${lang}`]}
              onChange={(e) => handleInputChange(`name${lang}`, lowerAndTrim(e.target.value), item.id)}
              className={errors[`streetName-${item.id}`] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`streets.${item.id}.roadLength`}>{t(`form.roadLength`, lang)}</label>
            <input
              id={`streets.${item.id}.roadLength`}
              name={`streets.${item.id}.roadLength`}
              placeholder={t(`form.roadLength`, lang)}
              type="number"
              value={item.totalStreetLength}
              onChange={(e) => handleInputChange('totalStreetLength', e.target.value, item.id)}
              step="0.0001"
              min={0}
              className={errors[`roadLength-${item.id}`] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`streets.${item.id}.repairRequired`}>{t(`form.lightingRequired`, lang)}</label>
            <select
              id={`streets.${item.id}.repairRequired`}
              name={`streets.${item.id}.repairRequired`}
              placeholder={t(`form.repairRequired`, lang)}
              value={item.repairRequired}
              onChange={(e) => handleInputChange('repairRequired', e.target.value, item.id)}
              defaultValue={""}
              className={errors[`repairRequired-${item.id}`] ? 'error' : ''}
            >
              <option value="" hidden></option>
              <option value='true'>{t('true', lang)}</option>
              <option value="false">{t('false', lang)}</option>
            </select>
          </FormGroup>

          <FormGroup>
            <label htmlFor={`streets.${item.id}.factCondition`}>{t(`form.factCondition`, lang)}</label>
            <select
              id={`streets.${item.id}.factCondition`}
              name={`streets.${item.id}.factCondition`}
              onChange={(e) => handleInputChange('factCondition', e.target.value, item.id)}
              value={item.factCondition}
              placeholder={t(`form.factCondition`, lang)}
              className={errors[`factCondition-${item.id}`] ? 'error' : ''}
            >
              <option value="" selected hidden></option>
              {
                options.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
                ))
              }
            </select>
          </FormGroup>

          <FormGroup>
            <label htmlFor={`streets.${item.id}.needsRepair`}>{t(`form.repairRequired`, lang)}</label>
            <select
              id={`streets.${item.id}.needsRepair`}
              name={`streets.${item.id}.needsRepair`}
              placeholder={t(`form.repairRequired`, lang)}
              value={item.repairRequired}
              onChange={(e) => handleInputChange('repairRequired', e.target.value, item.id)}
              defaultValue={""}
              className={errors[`repairRequired-${item.id}`] ? 'error' : ''}
            >
              <option value="" hidden></option>
              <option value='true'>{t('true', lang)}</option>
              <option value="false">{t('false', lang)}</option>
            </select>
          </FormGroup>

          {(item.repairRequired === 'true' || item.repairRequired === true)
            && <DocumentationForm
              formData={formData}
              setFormData={setFormData}
              lang={lang}
              setFieldValue={setFieldValue}
              type='lighting'
              onSave={handleSave}
              errors={Object.keys(errors).reduce((acc: any, key: string) => { acc[key.split('-')[0]] = errors[key]; return acc }, {})}
              streetId={item.id}
            />}

          <ImageGrid
            formData={{ kato: formData.kato, id: item.id } as IProject}
            loadForm={loadForm}
            type={'lighting_street'} images={formatStreetImages(item.files)}
            lang={lang.toLowerCase()}
          />

        </FormBlock>
      )}

      <FormBlock>
        {isAddingStreet && <div className="row sb">
          <FormBlock >
            <FormGroup>
              <label className='required' htmlFor={'streetname'}>{t(`form.streetName`, lang)}</label>
              <input type="text"
                value={newStreet[`name${lang}` as keyof typeof newStreet]}
                onChange={(e) => setNewStreet({ ...newStreet, [`name${lang}`]: lowerAndTrim(e.target.value) })}
                className={errors['objectName-street'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={'roadLength'}>{t(`form.roadLength`, lang)}</label>
              <input type="number"
                value={newStreet.totalStreetLength}
                onChange={(e) => setNewStreet({ ...newStreet, totalStreetLength: e.target.value })}
                step="0.0001"
                min={0}
                className={errors['totalStreetLength-street'] ? 'error' : ''}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
              <select
                id={`factCondition`}
                name={`factCondition`}
                placeholder={t(`form.factCondition`, lang)}
                onChange={(e) => setNewStreet({ ...newStreet, factCondition: e.target.value })}
                defaultValue={""}
                className={errors['factCondition-street'] ? 'error' : ''}
                value={newStreet.factCondition}
              >
                <option value="" hidden></option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(option.label.toLowerCase(), lang)}
                  </option>
                ))}
              </select>
            </FormGroup>

            <FormGroup>
              <label className='required' htmlFor={`repairRequired`}>{t(`form.repairRequired`, lang)}</label>
              <select
                id={`repairRequired`}
                name={`repairRequired`}
                placeholder={t(`form.repairRequired`, lang)}
                onChange={(e) => setNewStreet({ ...newStreet, repairRequired: e.target.value })}
                defaultValue={""}
                value={newStreet.repairRequired}
                className={errors['repair-required-street'] ? 'error' : ''}
              >
                <option value="" hidden></option>
                <option value="true">{t('true', lang)}</option>
                <option value="false">{t('false', lang)}</option>
              </select>
            </FormGroup>

            {(newStreet.repairRequired === 'true' || newStreet.repairRequired === true)
              && <DocumentationForm
                formData={newStreet}
                setFormData={setNewStreet}
                lang={lang}
                setFieldValue={setFieldValue}
                type='lighting'
                onSave={handleSave}
                errors={Object.keys(errors).reduce((acc: any, key: string) => { acc[key] = errors[key]; return acc; }, {})}
              />}
          </FormBlock>
        </div>}

        {isAddingStreet
          ? <div style={{ display: 'flex', gap: '0.625rem' }}>
            <FormBlockButton onClick={addNewStreet}>{t('save', lang)}</FormBlockButton>
            <FormBlockButton onClick={() => setIsAddingStreet(false)}>{t('cancel', lang)}</FormBlockButton>
          </div>
          : <FormBlockButton onClick={createStreet}>
            <AddField /> {t('add-street', lang)}
          </FormBlockButton>
        }
      </FormBlock>

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='lighting'
        onSave={handleSave}
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  useEffect(() => {
    if (formData.totalStreetLength && formData.illuminatedStreetLength) {
      setLightingCover(+(+formData.illuminatedStreetLength * 100 / +formData.totalStreetLength).toFixed(3))
    } else {
      setLightingCover(0)
    }
  }, [formData.totalStreetLength, formData.illuminatedStreetLength])

  return (
    <Wrapper>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='lighting' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
    </Wrapper >
  )
}

export default LightingForm