import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { AreaIcon, ArrowDown, PopulationIcon, SalaryIcon, TrendUp } from '../../assets/icons';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next'
import { formatDate, getLang, isRegion } from '../../utils/helpers.utils';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .icon {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #F5F5F5;
  }

  .label {
    display: flex;
    flex-direction: column;
  }

  .other {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    flex-direction: column;
  }

  .box {
    width: 100%;
    display: flex;
    gap: 0.625rem;
    padding: 0 0.625rem;
    .top {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }

    .label {
      gap: 0.5rem;
    }
  }
`

const PopulationInfoContainer = styled.div`
  width: 100%; 
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border-radius: 10px;
  overflow-y: auto;
  background: #F5F5F5;

  ::-webkit-scrollbar {
    width: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9;
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;    
  }
`;

const TotalPopulation = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  width: 100%;

  .info {
    display: flex;
    gap: 0.875rem;
    align-items: center;
    width: 100%;
  }

  .icon {
    background: #FFFFFF;
  }

  .percentage {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    justify-self: flex-end;
    margin-left: auto;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: background: #FFFFFF;
      border: none;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
  }

  .value {
    display: flex;
    gap: 0.625rem;
  }

  .arrow {
    padding: 0.625rem;
    cursor: pointer;

    path {
      stroke: #DBDCE0;
    }
  }
`;

const VillagesPopulation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${Text}{
      font-family: Roboto;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    > div {
      width: 80px;
      text-align: center;
      white-space: nowrap;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;  
      border-bottom: 1px solid #DBDCE0;
    }

    .left {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Info: FC<{ info: any }> = ({ info }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    info &&
    <InfoContainer>
      <PopulationInfoContainer>
        <TotalPopulation>
          <div className="icon">
            <PopulationIcon />
          </div>

          <div className="label">
            <div>
              <Text fontSize='0.875rem' lineHeight='1' color='#606367' fontWeight={400}>{t('aktobe.population').split(',')[0]}</Text>
            </div>

            <div className='value'>
              <Text fontSize='1.5625rem' lineHeight='1' color='#118F84' fontWeight={700}>{info.population || 0}</Text>
              <div>
                <Text fontSize='0.75rem' lineHeight='1' color='#118F84' fontWeight={400}>{info.population && t('form.people')}</Text>
                <Text fontSize='0.75rem' lineHeight='1' color='#606367' fontWeight={400}>{formatDate(info.date).replaceAll('-', '.') || t('no-data')}</Text>
              </div>
            </div>
          </div>

          <div className="arrow" onClick={() => setIsOpen(!isOpen)}>
            <ArrowDown
              style={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          </div>

          {/* <div className='info'>
            <div className="percentage">
              <div className="icon">
                <TrendUp />
              </div>
              <Text fontSize='0.75rem' color='#202224' fontWeight={600}>{info.populationDynamic || 0}</Text>
            </div>
          </div> */}
        </TotalPopulation>

        {
          isOpen && <VillagesPopulation>
            <div className="header">
              <div className="left">
                <Text fontWeight={400} color='#606367' fontSize='0.5625rem'>{t('aktobe.name')}</Text>
              </div>
              <div className="right">
                {!isRegion(info.kato) && <Text fontWeight={400} color='#606367' fontSize='0.5625rem'>{isRegion(info.kato) ? t('aktobe.toOblCenter') : t('aktobe.toCenter')}</Text>}
                <Text fontWeight={400} color='#606367' fontSize='0.5625rem'>{t('aktobe.population')}</Text>
              </div>
            </div>

            <div className="body">
              {info.childInfoList.map((village: any) => (
                <div className='row'>
                  <div className="left">
                    <Text fontWeight={400} color='#606367' fontSize='0.875rem'>{village[`name${getLang()}`]}</Text>
                    <Text fontWeight={400} color={village.active ? '#2366CA' : '#606367'} fontSize='0.5625rem'>{village.subName}</Text>
                  </div>
                  <div className="right">
                    {!isRegion(info.kato) && <Text fontWeight={700} color='#818689' fontSize='1.125rem'>{village.distance} км </Text>}
                    <Text fontWeight={700} color='#818689' fontSize='1.125rem'>{village.population}</Text>
                  </div>
                </div>
              ))}
            </div>
          </VillagesPopulation>
        }
      </PopulationInfoContainer>

      <div className="other">
        <div className="box">
          <div className='top'>
            <div className="icon">
              <AreaIcon />
            </div>
            <div className="label">
              <Text lineHeight='1' fontWeight={400} fontSize='0.875rem' color='#606367'>{t('aktobe.area')}</Text>
              <Text lineHeight='1' fontWeight={700} fontSize='1.5625rem' color='#118F84'>{info.area ? `${info.area} га` : t('no-data')}</Text>
            </div>
          </div>
        </div>

        <div className="box">
          <div className='top'>
            <div className="icon">
              <SalaryIcon />
            </div>
            <div className="label">
              <Text lineHeight='1' fontWeight={400} fontSize='0.875rem' color='#606367'>{t('aktobe.avgSalary')}</Text>
              <Text lineHeight='1' fontWeight={700} fontSize='1.5625rem' color='#118F84'>{info.avgSalary ? `${info.avgSalary} ₸` : t('no-data')} </Text>
            </div>
          </div>
        </div>
      </div>
    </InfoContainer >
  )
}

export default Info