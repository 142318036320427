import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Header from './header.component';
import Search from './search.component';
import CurrentSnp from './current-snp.component';
import Passport from './passport.component';

import { Enter, Settings, LoginIcon } from '../../assets/icons';

import { LeftMenuContainer, LoginContainer } from './index.styles';
import { IMarker, IRegion, ISnp } from '../../interfaces/snp.interface';
import { login } from '../../requests/auth.request';
import { removeTokens, setTokens } from '../../requests/token.request';
import { getGeneralInfo } from '../../requests/snp.request';
import { getMarkers, getRegionList, getSettlementTree } from '../../requests/kato.request';
import i18n from 'i18next';
import { getLang } from '../../utils/helpers.utils';
import { useParams } from 'react-router-dom';
import PhotoSwiper from './photo-swiper.components';
import Info from './info.component';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string) => void;
  selectedRegion: IRegion;
  setSelectedRegion: (region: IRegion) => void;
  selectedMarker: IMarker;
  setSelectedMarker: (marker: IMarker) => void;
  markers: IMarker[];
  setZoomedRegion: (region: IRegion | null) => void;
}

const LeftMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedRegion, setSelectedRegion, selectedMarker, setSelectedMarker, setZoomedRegion }) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  const { link, kato } = useParams()

  const [chosenLanguage, setChosenLanguage] = useState<'RU' | 'KZ'>('KZ');
  const [searchValue, setSearchValue] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [passportInfo, setPassportInfo] = useState<any>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [listOptions, setListOptions] = useState<{ label: string, value: ISnp }[]>([]);
  const [info, setInfo] = useState<any>(null)
  const [photos, setPhotos] = useState<any[]>([])

  const handleLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChosenLanguage(e.target.checked ? 'RU' : 'KZ');
    localStorage.setItem('lng', e.target.checked ? 'ru' : 'kz');
    i18n.changeLanguage(e.target.checked ? 'ru' : 'kz');
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const latinicPattern = /[A-Za-z]/;
    if (latinicPattern.test(inputValue)) {
      return;
    }
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSearch = () => {
    console.log('searching');
  };

  const handleLogin = () => {
    login(username, password)
      .then(res => {
        if (res.success) {
          setTokens(res.access, res.refresh)
          localStorage.setItem('user', JSON.stringify(res.userInfo));
          setIsLoggedin(true);
          setUserInfo(res.userInfo);
        }
      })
      .catch(err => {
        setErrorMsg(err.response.data.msg);
      });
  }

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedin(false);
    setUserInfo(null);
    removeTokens();
  }

  const handleGoToAdminPanel = () => {
    navigate('/admin/snp');
  }

  const handleSelectOption = (option: any) => {
    link ? navigate(`/${option.kato}/${link}`) : navigate(`/${option.kato}`);
    setSelectedMarker(option);
  }

  const getOptions = () => {
    getSettlementTree(language.toUpperCase() as 'RU' | 'KZ').then(res => {
      getRegionList().then((regionList) => {
        const options = regionList
          .map((item: any) => {
            const found: any = res.data[item[`name${getLang()}`]] || {};
            const children: any = Object
              .keys(found)
              .map((item: string) => ({ kato: found[item][0].pkato, nameKz: found[item][0].pnameKz, nameRu: found[item][0].pnameRu }));
            return ({ ...item, children })
          })

        setListOptions(options)
      })
    })
  }

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setIsLoggedin(true);
      setUserInfo(JSON.parse(userData));
    }

    const lang = localStorage.getItem('lng');
    if (lang) {
      i18n.changeLanguage(lang);
      setChosenLanguage(lang === 'ru' ? 'RU' : 'KZ');
      getOptions()
    } else {
      i18n.changeLanguage('ru');
      setChosenLanguage('RU');
      getOptions()
    }
  }, []);

  useEffect(() => {
    if (kato) {
      const snpData = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato || snpData.kato;
      if (val) {
        getGeneralInfo(val).then((res: any) => {
          if (res) {
            setInfo(res)
            setPassportInfo([{ ...res.akim, type: 's' }]);
            setPhotos(res.imageIds)
          }
        })
      }
    }
  }, [kato]);

  useEffect(() => {
    if (selectedRegion && selectedMarker && selectedMarker.kato) {
      getGeneralInfo(selectedMarker.kato).then((res: any) => {
        if (res) {
          setInfo(res)
          setPassportInfo([{ ...res.akim, type: 's' }]);
          setPhotos(res.imageIds)
        }
      })
    } else if (selectedRegion && selectedRegion.kato && !selectedMarker) {
      getGeneralInfo(selectedRegion.kato).then((res: any) => {
        // if (res) {
        //   setInfo(res)
        //   setPassportInfo([{ ...res.akim, type: 's' }]);
        //   setPhotos(res.imageIds)
        // }
      })
    }
  }, [selectedRegion, selectedMarker])

  useEffect(() => {
    setErrorMsg('');
  }, [username, password]);

  useEffect(() => {
    getOptions();
  }, [language])

  return (
    <LeftMenuContainer active={showLogin}>
      <Header
        chosenLanguage={chosenLanguage}
        onChange={handleLanguageChange}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
      />

      {
        showLogin
          ? <LoginContainer>
            <div className="block">
              <div className="heading">
                <Settings />
                {t('settings')}
              </div>
            </div>

            {
              isLoggedin
                ? <div className="block">
                  <div className="heading">
                    <LoginIcon />
                    <span className="username">
                      {userInfo.name || 'Username'}
                    </span>
                  </div>

                  <button onClick={handleGoToAdminPanel}>
                    {t('loginToAdminPanel')}
                    <Enter />
                  </button>
                  <button onClick={handleLogout}>
                    {t('logout')}
                    <Enter />
                  </button>
                </div>
                : <div className="block">
                  <div className="heading">
                    <LoginIcon />
                    {t('loginToAdminPanel')}
                  </div>

                  <input
                    type="text"
                    placeholder={t('username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={errorMsg.length > 0 ? 'input-error' : ''}
                  />
                  <input
                    type="password"
                    placeholder={t('password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={errorMsg.length > 0 ? 'input-error' : ''}
                  />

                  {errorMsg && <div className="error">{errorMsg}</div>}
                  <button onClick={handleLogin}>
                    {t('login')}
                    <Enter />
                  </button>
                </div>
            }

            {
              <div className="add-info">
                <a href="https://adilet.zan.kz/rus/docs/V15C0004686" target='_blank' rel="noreferrer">{t('RegionImprovementRules')}</a>
              </div>
            }
          </LoginContainer>
          : <>
            {
              listOptions && listOptions.length > 0
              && <Search
                searchValue={searchValue}
                onChange={handleSearchChange}
                onClear={clearSearch}
                onSearch={handleSearch}
                options={listOptions}
                onSelectOption={handleSelectOption}
              />
            }
            <CurrentSnp selectedSnp={selectedMarker} selectedRegion={selectedRegion} />

            {
              passportInfo && <div className="scrollable">
                {passportInfo && <Passport people={passportInfo} />}

                <PhotoSwiper photos={photos} />

                <Info info={info} />
              </div>
            }
          </>
      }

    </LeftMenuContainer>
  )
}

export default LeftMenu