import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

import ImageGrid from '../insfrastructureProjects/image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';

import {
  AddField
} from '../../assets/icons';

import { getProject } from '../../requests/snp.request';
import {
  createObject,
  updateObject
} from '../../requests/supervisor.request';

import {
  FooterButton,
  FormGroup,
  Wrapper,
  FormBlock
} from './admin-page.styles';

import { capitalize, checkVal, formatNumber, lowerAndTrim, t } from '../../utils/helpers.utils';
import { FormBlockButton } from '../insfrastructureProjects/infrastructure.styles';
import FieldsComponent from '../insfrastructureProjects/fields.components';
import FormFooter from '../insfrastructureProjects/footer.component';

const defaultObj = {
  labelRu: null,
  labelKz: null,
  sum: null,
  year: 2023,
}

const initialFormData = {
  totalSum: 0,
  memorandum: [
    {
      labelRu: null,
      labelKz: null,
      sum: null,
      year: 2023,
    },
    {
      labelRu: null,
      labelKz: null,
      sum: null,
      year: 2024,
    },
    {
      labelRu: null,
      labelKz: null,
      sum: null,
      year: 2025,
    },
  ]
}

const checkList = [
  "nameKz",
  "nameRu",
  "supervisorNameKz",
  "supervisorNameRu",
  "employeeNumber",
]

const errMsg: any = {
  "nameKz": "company-name",
  "nameRu": "company-name",
  "supervisorNameKz": "supervisorName",
  "supervisorNameRu": "supervisorName",
  "employeeNumber": "employeeNumber",
  "memorandumTotalPurposeKz": "totalPurpose",
  "memorandumTotalPurposeRu": "totalPurpose",
}


const AgricultureProducersForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const formikRef = useRef<any>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [isAddingPower, setIsAddingPower] = useState(false);
  const [newPowerField, setNewPowerField] = useState<{ labelRu: string, labelKz: string, value: string }>({ labelRu: '', labelKz: '', value: '' });
  const [errors, setErrors] = useState<any>({});

  const handleSave = (values: any, addPower = { ...values.additionalPowerFields, ...formData.additionalPowerFields }) => {
    setErrors({})
    const avgSalary = formData.avgSalary && +(formData.avgSalary.toString().split('').filter((item: string) => +item >= 1).join(''))
    const data = {
      ...values,
      additionalFields: { ...values.additionalFields, ...formData.additionalFields },
      additionalPowerFields: addPower,
      avgSalary,
      memorandum: formData.memorandum
    }

    delete data.totalSum;

    for (const key of checkList) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (!checkVal(data.plantPower) && !checkVal(data.lifestockPower)) {
      setErrors((prev: any) => ({ ...prev, 'one-of-power': true }))
      toast.error(t(`errors.one-of-power`, language))
      return
    }

    if (checkVal(data.memorandum)) {
      if (data.memorandum.some((item: any) => checkVal(item.sum))) {
        for (const item of data.memorandum) {
          if (checkVal(item.sum) && (!checkVal(item.labelRu) || !checkVal(item.labelKz))) {
            setErrors((prev: any) => ({ ...prev, [`label-${item.year}`]: true }))
            toast.error(t(`errors.memorandumPurpose`, language).replace('{{year}}', item.year))
            return
          }
        };
      }

      if (data.memorandum.length > 0
        && data.memorandum.some((item: any) => checkVal(item.sum) || checkVal(item.labelRu) || checkVal(item.labelKz))
        && (!checkVal(data.memorandumTotalPurposeKz) || !checkVal(data.memorandumTotalPurposeRu))) {
        setErrors((prev: any) => ({ ...prev, totalPurpose: true }))
        toast.error(t(`errors.totalPurpose`, language))
        return
      }
    }

    if (formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, [`files`]: true }))
      toast.error(t(`errors.files`, language))
      return
    }

    updateObject('SNP_AGRICULTURE_PRODUCERS', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const handleAddField = () => {
    setErrors({})

    if (!newPowerField.labelKz || !newPowerField.labelRu || !newPowerField.value) {
      setErrors((prev: any) => ({ ...prev, newField: true }));
      toast.error(t(`errors.fill-field`, language));
      return;
    };

    const field = {
      ...formData.additionalPowerFields,
      [+new Date()]: newPowerField
    }

    const data = {
      ...formData,
      additionalPowerFields: field,
    }

    delete data.totalSum;

    setFormData(data)

    handleSave(data)

    setNewPowerField({ labelRu: '', labelKz: '', value: '' })
    setIsAddingPower(false)
  }

  const handleDeletePowerField = (label: string) => {
    const { [label]: deleted, ...rest } = formData.additionalPowerFields;

    setFormData({
      ...formData,
      additionalPowerFields: rest
    })

    handleSave({
      ...formData,
      additionalPowerFields: rest
    }, rest)
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('SNP_AGRICULTURE_PRODUCERS', objectId).then(res => {
      const data = {
        ...res.infrastructureEntity,
        supervisorNameKz: capitalize(res.infrastructureEntity.supervisorNameKz || ''),
        supervisorNameRu: capitalize(res.infrastructureEntity.supervisorNameRu || ''),
      };
      setFormData(data)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('SNP_AGRICULTURE_PRODUCERS', val).then(res => {
        navigate(`/admin/${val}/snp_agriculture_producers/${res.id}`);
      })
    }
  }, [kato, navigate, objectId])

  const handleSalaryChange = (val: any, setFieldValue: any) => {
    const value = formatNumber(val);

    if (value && isNaN(+value.replace(' ', ''))) {
      return;
    }

    setFieldValue('avgSalary', (+value).toLocaleString('ru-RU'))
    setFormData({
      ...formData,
      avgSalary: (+value).toLocaleString('ru-RU')
    })
  }

  const handleMemorandumValueChange = (key: any, val: any, year: string) => {
    const found = formData.memorandum?.find((item: any) => item.year === year);
    if (found) {
      found[key] = val;
      setFormData({
        ...formData,
        memorandum: formData.memorandum
          ?.map((item: any) => item.year === year
            ? { ...item, [key]: val }
            : item)
      })
    } else {
      setFormData({
        ...formData,
        memorandum: formData.memorandum
          ? [...formData.memorandum, { ...defaultObj, [key]: val, year }]
          : [{ ...defaultObj, [key]: val, year }],
      })
    }
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  useEffect(() => {
    if (formikRef?.current) {
      const totalSum = formikRef?.current?.values?.memorandum?.reduce((acc: number, item: any) => {
        if (!isNaN(+item.sum)) {
          return acc + +item.sum
        } else {
          return acc
        }
      }, 0)
      formikRef?.current?.setValues({ ...formikRef?.current?.values, totalSum })
    }
  })

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`agriculture_producers.name`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`agriculture_producers.name${lang}`)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['company-name'] ? 'error' : ''}
        />
      </FormGroup>

      <FormBlock type='gray' responsive>
        <FormGroup>
          <label className='required' htmlFor={`supervisorName${lang}`}>{t(`agriculture_producers.supervisorName`, lang)}</label>
          <Field
            id={`supervisorName${lang}`}
            name={`supervisorName${lang}`}
            placeholder={t(`agriculture_producers.supervisorName`, lang)}
            onChange={(e: any) => setFieldValue(`supervisorName${lang}`, capitalize(e.target.value))}
            className={`capitalize ${errors.supervisorName ? 'error' : ''}`}
          />
        </FormGroup>
      </FormBlock>

      <FormBlock type='white'>
        <FormGroup>
          <label htmlFor={`plantPower`}>{t(`agriculture_producers.plantPower`, lang)}</label>
          <Field
            id={`plantPower`}
            name={`plantPower`}
            placeholder={t(`agriculture_producers.plantPower`, lang)}
            as={'input'}
            type="number"
            step="0.001"
            onChange={(e: any) => {
              setFieldValue('plantPower', e.target.value.replace(',', '.'));
              setFormData({ ...formData, plantPower: e.target.value.replace(',', '.') })
            }}
            className={errors['one-of-power'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`lifestockPower`}>{t(`agriculture_producers.lifestockPower`, lang)}</label>
          <Field
            id={`lifestockPower`}
            name={`lifestockPower`}
            placeholder={t(`agriculture_producers.lifestockPower`, lang)}
            as={'input'}
            type="number"
            step="0.001"
            onChange={(e: any) => {
              setFieldValue('lifestockPower', e.target.value.replace(',', '.'));
              setFormData({ ...formData, lifestockPower: e.target.value.replace(',', '.') })
            }}
            className={errors['one-of-power'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`employeeNumber`}>{t(`agriculture_producers.employeeNumber`, lang)}</label>
          <Field
            id={`employeeNumber`}
            name={`employeeNumber`}
            placeholder={t(`agriculture_producers.employeeNumber`, lang)}
            as={'input'}
            type="number"
            step="0.001"
            onChange={(e: any) => {
              setFieldValue('employeeNumber', e.target.value.replace(',', ''));
              setFormData({ ...formData, employeeNumber: e.target.value.replace(',', '') })
            }}
            className={errors['employeeNumber'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`avgSalary`}>{t(`agriculture_producers.avgSalary`, lang)} {t('tenge', lang)}</label>
          <input
            id={`avgSalary`}
            name={`avgSalary`}
            placeholder={t(`agriculture_producers.avgSalary`, lang)}
            value={(+formatNumber(formData.avgSalary)).toLocaleString('ru-RU')}
            type="text"
            onChange={(e: any) => handleSalaryChange(e.target.value.trim(), setFieldValue)} />
        </FormGroup>

        {formData.additionalPowerFields && Object.keys(formData.additionalPowerFields).map((key) =>
          <>
            <FormGroup>
              <label htmlFor={`additionalFields.${key}.label${lang}`}>{formData.additionalPowerFields[key][`label${lang}` as any]}</label>
              <div className='row sb'>
                <Field
                  id={`additionalPowerFields.${key}.value`}
                  name={`additionalPowerFields.${key}.value`}
                  value={formData.additionalPowerFields[key].value}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`additionalPowerFields.${key}.value`, lowerAndTrim(e.target.value))
                      setFormData({ ...formData, additionalPowerFields: { ...formData.additionalPowerFields, [key]: { ...formData.additionalPowerFields[key], value: lowerAndTrim(e.target.value) } } })
                    }}
                />
                <FooterButton variant='delete' onClick={() => handleDeletePowerField(key)}>x</FooterButton>
              </div>
            </FormGroup>
          </>
        )}

        {isAddingPower && <div className="row sb">
          <FormGroup className='half'>
            <input type="text"
              value={newPowerField[`label${lang}`]}
              onChange={(e) => setNewPowerField({ ...newPowerField, [`label${lang}`]: lowerAndTrim(e.target.value) })}
              placeholder={t('index-name', lang)}
              className={errors.newField ? 'error' : ''}
            />
            <input type="text"
              value={newPowerField.value}
              onChange={(e) => setNewPowerField({ ...newPowerField, value: e.target.value })}
              placeholder={t('index-value', lang)}
              className={errors.newField ? 'error' : ''}
            />
          </FormGroup>
        </div>}

        {isAddingPower
          ? <div style={{ display: 'flex', gap: '0.625rem' }}>
            <FormBlockButton onClick={handleAddField}>{t('save', lang)}</FormBlockButton>
            <FormBlockButton onClick={() => setIsAddingPower(false)}>{t('cancel', lang)}</FormBlockButton>
          </div>
          : <FormBlockButton onClick={() => setIsAddingPower(true)}>
            <AddField /> {t('add-index', lang)}
          </FormBlockButton>
        }
      </FormBlock>

      <FormBlock type='white'>
        <FormGroup>
          <label htmlFor={`totalSum`}>{t(`agriculture_producers.totalSum`, lang)}</label>
          <Field
            id={`totalSum`}
            name={`totalSum`}
            as={'input'}
            type="number"
            step="0.001"
            disabled
            placeholder={'0'}
            className='readonly'
          />
          <label htmlFor={`memorandumTotalPurpose${lang}`}>{t(`agriculture_producers.totalPurpose`, lang)}</label>
          <Field
            id={`memorandumTotalPurpose${lang}`}
            name={`memorandumTotalPurpose${lang}`}
            placeholder={t(`agriculture_producers.totalPurposePlaceholder`, lang)}
            onChange={(e: any) => setFieldValue(`memorandumTotalPurpose${lang}`, lowerAndTrim(e.target.value))}
            className={errors['totalPurpose'] ? 'error' : ''}
          />
        </FormGroup>

        {[2023, 2024, 2025].map((year, index) => (
          <FormGroup className='half'>
            <label className='bold-label' htmlFor={`memorandum[${index}`}>{year} {t('year', lang)}:</label>
            <Field
              id={`memorandum[${index}].sum`}
              name={`memorandum[${index}].sum`}
              placeholder={t(`agriculture_producers.memorandumSum`, lang)?.replace('{{year}}', year)}
              as={'input'}
              type="number"
              step="0.001"
              onChange={(e: any) => {
                setFieldValue(`memorandum[${index}].sum`, e.target.value.replace(',', '.'));
                setFieldValue(`memorandum[${index}].year`, year.toString());
                handleMemorandumValueChange('sum', e.target.value.replace(',', '.'), year.toString());
              }}
              value={formData.memorandum && formData.memorandum?.find((item: any) => +item.year === +year)?.sum}
            />

            {
              formData && formData.memorandum && formData.memorandum?.find((item: any) => +item.year === +year)?.sum && <Field
                id={`memorandum[${index}].label${lang}`}
                name={`memorandum[${index}].label${lang}`}
                placeholder={t(`agriculture_producers.purpose`, lang)}
                onChange={(e: any) => {
                  setFieldValue(`memorandum[${index}].label${lang}`, lowerAndTrim(e.target.value));
                  handleMemorandumValueChange(`label${lang}`, lowerAndTrim(e.target.value), year.toString());
                }}
                className={errors[`label-${year}`] ? 'error' : ''}
              />
            }

          </FormGroup>
        ))}
      </FormBlock>

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='agriculture'
        onSave={handleSave}
      />
    </>
  }

  return (
    <Wrapper>
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
            innerRef={formikRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='SNP_AGRICULTURE_PRODUCERS' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />

      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'SNP_AGRICULTURE_PRODUCERS'}
        images={images}
        lang={language}
      />

    </Wrapper >
  )
}

export default AgricultureProducersForm