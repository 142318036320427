import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useMap, Map } from '@vis.gl/react-google-maps';
import styled from "styled-components";
import { IMarker, IRegion } from "../../interfaces/snp.interface";
import JSZip from 'jszip';
import { aktobeDistrictsWithNames, irgizDistrictsWithNames } from "../../constants/snp.constant";
import { getLang } from '../../utils/helpers.utils';

const MapWrapper = styled.div`
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
`;

const MapSearch = styled.input`
  width: 15rem;
  height: 2rem;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  outline: none;
  margin: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.50);
`;

const MapPlaceControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 10px;
`;

const MapPlaceControlButton = styled.button`
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: white;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(60,64,67,0.3),
              0 4px 8px 3px rgba(60,64,67,0.15);
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.50);
  }
`;

type Props = {
  setSelectedRegion: Dispatch<SetStateAction<IRegion | undefined>>;
  setSelectedMarker: Dispatch<SetStateAction<IMarker | null>>;
}

const GoogleMapComponent: React.FC<Props> = ({ setSelectedRegion, setSelectedMarker }) => {
  const map = useMap();

  const styles: Record<string, google.maps.MapTypeStyle[]> = {
    default: [],
    hide: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const extractKato = (str: string) => {
    const match = str.match(/Като:\s*(\d+)/);
    return match ? +match[1] : null;
  };

  const fetchKmzData = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const zip = await JSZip.loadAsync(blob);
    const kmlFile = Object.keys(zip.files).find((file) => file.endsWith('.kml'));
    if (kmlFile) {
      const kmlContent = await zip.files[kmlFile].async('text');
      return new DOMParser().parseFromString(kmlContent, 'application/xml');
    }
    return null;
  };

  useEffect(() => {
    if (map) {
      const aqtobeRegionsLayer = new google.maps.KmlLayer({
        url: 'https://qabduali.github.io/kml-hosting/aqtobe-regions-3.kmz',
        suppressInfoWindows: true,
        preserveViewport: true,
        zIndex: 100,
        screenOverlays: false,
        map,
      });

      const irgizRegionsLayer = new google.maps.KmlLayer({
        url: 'https://qabduali.github.io/kml-hosting/irgiz-1.kmz',
        suppressInfoWindows: true,
        preserveViewport: true,
        zIndex: 101,
      });

      const irgizVillagesLayer = new google.maps.KmlLayer({
        url: 'https://qabduali.github.io/kml-hosting/irgiz-villages.kmz',
        suppressInfoWindows: true,
        preserveViewport: true,
        zIndex: 200,
      });

      let currentInfoWindow: google.maps.InfoWindow | null = null;
      let irgizVillageMarkers: google.maps.Marker[] = [];
      let regionLabelMarkers: google.maps.Marker[] = [];
      let districtLabelMarkers: google.maps.Marker[] = [];

      const handleIrgizVillageClick = (event: any) => {
        const { featureData, latLng } = event;

        if (featureData && latLng) {
          const kato = extractKato(featureData?.snippet);
          const regionName = featureData.description.split('<br>')[1].split(':')[1].trim();

          if (kato) {
            const snp: IRegion = {
              nameKz: featureData.name,
              nameRu: featureData.name,
              NAME_EN: featureData.name,
              coordinates: [latLng.lat(), latLng.lng()],
              kato,
            };

            setSelectedMarker(snp as any);
            setSelectedRegion({ [`name${getLang()}`]: regionName, kato: kato.toString().slice(0, 4) + '00000' } as any);
          }

          const content = `
            <div>
              <h4>${featureData.name}</h4>
            </div>
          `;

          if (currentInfoWindow) {
            currentInfoWindow.close();
          }

          const infoWindow = new google.maps.InfoWindow({
            content: content,
            position: latLng,
          });

          infoWindow.open(map);

          currentInfoWindow = infoWindow;
        }
      };

      const handleIrgizMarkerClick = (event: any) => {
        const info = event.domEvent.target.parentElement.title.split(' ');
        const kato = info[0];
        const name = info[1];
        const latLng = event.latLng;

        if (kato && name) {
          const snp: IRegion = {
            nameKz: name,
            nameRu: name,
            NAME_EN: name,
            coordinates: [latLng.lat(), latLng.lng()],
            kato,
          };

          setSelectedMarker(snp as any);
        //   const content = `
        //   <div>
        //     <h4>${name}</h4>
        //   </div>
        // `;

        //   if (currentInfoWindow) {
        //     currentInfoWindow.close();
        //   }

          // const infoWindow = new google.maps.InfoWindow({
          //   content: content,
          //   position: latLng,
          // });

          // infoWindow.open(map);

          // currentInfoWindow = infoWindow;
        }
      }

      aktobeDistrictsWithNames.forEach((disctrict) => {
        const marker = new google.maps.Marker({
          position: { lng: disctrict.coordinates[0], lat: disctrict.coordinates[1] },
          title: disctrict.nameRu,
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/transparent.png',
            size: new google.maps.Size(0, 0),
          },
          label: {
            text: disctrict.nameRu,
            color: '#000',
            fontWeight: 'bold',
            fontSize: '14px',
            className: 'custom-label',
          },
          map,
        });
        regionLabelMarkers.push(marker)
      })

      irgizDistrictsWithNames.forEach((disctrict) => {
        const marker = new google.maps.Marker({
          position: { lng: disctrict.coordinates[0], lat: disctrict.coordinates[1] },
          title: disctrict.nameRu,
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/transparent.png',
            size: new google.maps.Size(0, 0),
          },
          label: {
            text: disctrict.nameRu,
            color: '#000',
            fontWeight: 'bold',
            fontSize: '14px',
            className: 'custom-label',
          },
          map,
        });
        districtLabelMarkers.push(marker)
      })

      districtLabelMarkers.forEach((mark) => mark.setMap(null));

      const addMarkersFromKmz = async (kmzUrl: string) => {
        const kmlDoc = await fetchKmzData(kmzUrl);
        if (!kmlDoc) {
          return;
        }

        const placemarks = kmlDoc.getElementsByTagName('Placemark');
        for (let i = 0; i < placemarks.length; i++) {

          const name = placemarks[i].getElementsByTagName('name')[0].textContent;
          const coordinates = placemarks[i]?.getElementsByTagName('coordinates')?.[0].textContent?.trim().split(',');
          const kato = extractKato(placemarks[i].textContent as string);
          const iconUrl = Array.from(placemarks[i].childNodes).find((node) => node.nodeName === 'styleUrl')?.textContent;

          if (name && coordinates && coordinates[0] && coordinates[1] && kato && iconUrl) {
            const latLng = new google.maps.LatLng(parseFloat(coordinates[1]), parseFloat(coordinates[0]));
            const marker = new google.maps.Marker({
              position: latLng,
              title: kato.toString() + ' ' + name,
              icon: {
                url: 'https://cdn-icons-png.flaticon.com/512/12631/12631940.png',
                labelOrigin: new google.maps.Point(45, -15),
                scaledSize: new google.maps.Size(32, 32),
              },
              label: {
                // text: name,
                text: ' ',
                color: '#0088D1',
                fontWeight: 'bold',
                fontSize: '13px',
                className: 'custom-label',
              },
            });
            irgizVillageMarkers.push(marker);

            // eslint-disable-next-line no-loop-func
            google.maps.event.addListener(marker, 'click', handleIrgizMarkerClick);
          }
        }
      };

      const zoomChangeListener = google.maps.event.addListener(map, 'zoom_changed', () => {
        const zoomLevel = map.getZoom();
        if (zoomLevel && zoomLevel >= 12) {
          aqtobeRegionsLayer?.setMap(null);
          irgizRegionsLayer.setMap(null);
        } else if (zoomLevel && zoomLevel < 8) {
          irgizRegionsLayer?.setMap(null);
          irgizVillagesLayer?.setMap(null);
          aqtobeRegionsLayer?.setMap(map);
          irgizVillageMarkers.forEach((mark) => mark.setMap(null));
          regionLabelMarkers.forEach((mark) => mark.setMap(map));
          districtLabelMarkers.forEach((mark) => mark.setMap(null));
        } else {
          aqtobeRegionsLayer?.setMap(map);
          regionLabelMarkers.forEach((mark) => mark.setMap(null));
        }
      });

      const regionClickListener = google.maps.event.addListener(aqtobeRegionsLayer, 'click', (event: google.maps.KmlMouseEvent) => {
        const zoom = map.getZoom();
        if (currentInfoWindow) {
          currentInfoWindow.close();
        }
        if (zoom && zoom >= 8.5) {
          map.setZoom(7);
          map.setCenter({ lat: 48.420685, lng: 57.809909 });
        } else {
          map.setZoom(8.5);
          if (event.latLng && event.featureData) {
            const position = event.latLng;
            map.setCenter(position);
            setSelectedRegion({ [`name${getLang()}`]: event.featureData.description.split('<br>')[0], kato: +event.featureData.name } as any);
            setSelectedMarker(null);
            irgizRegionsLayer?.setMap(map);
            districtLabelMarkers.forEach((mark) => mark.setMap(map));
          }
        }
      });

      const districtClickListener = google.maps.event.addListener(irgizRegionsLayer, 'click', (event: google.maps.KmlMouseEvent) => {
        if (event.featureData) {
          setSelectedMarker({
            [`name${getLang()}`]: event.featureData.name,
            kato: +event.featureData.description
          } as any);
          setSelectedRegion({ [`name${getLang()}`]: "Иргизский", kato: 156800000 } as any);
          if (event.featureData?.description) {
            irgizVillageMarkers.forEach((mark) => mark.setMap(null));
            irgizVillageMarkers.filter((mark: any) => mark.title.startsWith(event.featureData?.description.slice(0, 6))).forEach((mark) => mark.setMap(map));
          }
        }
      });

      const input = document.getElementById('pac-input') as HTMLInputElement;

      input.addEventListener('input', () => {
        if (input.value === '') {
          clearMarkers();
          updateMapStyles(false);
        }
      });

      const searchBox = new google.maps.places.SearchBox(input);

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(input);

      let markers: google.maps.Marker[] = [];
      let infoWindow: google.maps.InfoWindow | null = null;

      const clearMarkers = () => {
        markers.forEach(marker => marker.setMap(null));
        markers = [];
        if (infoWindow) {
          infoWindow.close();
        }
      };

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (!places || places.length === 0) {
          clearMarkers();
          updateMapStyles(false);
          return;
        }

        clearMarkers();

        const newMarkers: google.maps.Marker[] = [];

        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          const marker = new google.maps.Marker({
            map,
            title: place.name,
            position: place.geometry.location,
          });

          marker.addListener('click', () => {
            if (infoWindow) {
              infoWindow.close();
            }
            const newInfoWindow = new google.maps.InfoWindow({
              content: `<div><h4>${place.name}</h4></div>`,
            });
            newInfoWindow.open(map, marker);
            infoWindow = newInfoWindow;
          });

          newMarkers.push(marker);

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        markers = newMarkers;
        map.fitBounds(bounds);

        updateMapStyles(newMarkers.length > 0);
      });

      map.addListener('click', (event: google.maps.IconMouseEvent) => {
        if (!event.placeId) {
          map.setZoom(7);
        }
      });

      const placesService = new google.maps.places.PlacesService(map);

      const searchPlaces = (type: string) => {
        const request = {
          location: map.getCenter(),
          radius: 5000,
          type,
        };

        placesService.nearbySearch(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            clearMarkers();

            const newMarkers: google.maps.Marker[] = [];

            results.forEach((place) => {
              const marker = new google.maps.Marker({
                map,
                position: place.geometry?.location,
                title: place.name,
              });

              marker.addListener('click', () => {
                if (infoWindow) {
                  infoWindow.close();
                }
                const newInfoWindow = new google.maps.InfoWindow({
                  content: `<div><h4>${place.name}</h4></div>`,
                });
                newInfoWindow.open(map, marker);
                infoWindow = newInfoWindow;
              });

              newMarkers.push(marker);
            });

            markers = newMarkers;
            updateMapStyles(newMarkers.length > 0);
          }
        });
      };

      const centerControlDiv = document.getElementById('controls');
      if (centerControlDiv) {
        Array.from(centerControlDiv.children).forEach((item) => {
          item.addEventListener('click', () => {
            const name = (item as HTMLButtonElement).name;
            if (name === 'clear') {
              clearMarkers();
              updateMapStyles(false);
            } else {
              searchPlaces(name);
            }
          });
        });
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
      }

      const updateMapStyles = (hasMarkers: boolean) => {
        if (hasMarkers) {
          map.setOptions({ styles: styles['hide'] });
        } else {
          map.setOptions({ styles: styles['default'] });
        }
      };

      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      google.maps.event.addListener(irgizVillagesLayer, 'click', handleIrgizVillageClick);
      void addMarkersFromKmz('https://qabduali.github.io/kml-hosting/irgiz-villages.kmz');

      return () => {
        google.maps.event.removeListener(regionClickListener);
        google.maps.event.removeListener(districtClickListener);
        google.maps.event.removeListener(zoomChangeListener);
      };
    }
  }, [map]);

  return (
    <MapWrapper>
      <MapSearch type={'search'} id={'pac-input'} placeholder={'Поиск в Google картах'} />
      <Map
        defaultZoom={6}
        fullscreenControl={false}
        streetViewControl={false}
        defaultCenter={{ lat: 48.420685, lng: 57.809909 }}
      />
      <MapPlaceControlsContainer id={'controls'}>
        <MapPlaceControlButton name={'pharmacy'}>Аптеки</MapPlaceControlButton>
        <MapPlaceControlButton name={'groceries'}>Продукты</MapPlaceControlButton>
        <MapPlaceControlButton name={'hospital'}>Больницы</MapPlaceControlButton>
        <MapPlaceControlButton name={'clear'}>Очистить</MapPlaceControlButton>
      </MapPlaceControlsContainer>
    </MapWrapper>
  );
};

export default GoogleMapComponent;