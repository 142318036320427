import React, { FC } from 'react';
import { BasicStatsContainer, BasicStatsItem } from './index.styles';
import { useTranslation } from 'react-i18next';
import { getLang } from '../../utils/helpers.utils';
import { BeefIcon, ChickenIcon, RoadIcon, TrendDown, TrendUp } from '../../assets/icons';
import { Text } from '../text.component';

interface IProps {
  basicStats: any;
  additionalStats: any;
}

const properties: any = {
  ROAD: {
    unit: '%',
    NONE: {
      icon: <RoadIcon />,
      hasIcon: true
    }
  },
  FOOD: {
    unit: '%',
    BEEF: {
      icon: <BeefIcon />,
      hasIcon: true
    },
    CHICKEN: {
      icon: <ChickenIcon />,
      hasIcon: true
    }
  },
  SER: {
    unit: "млн. тг.",
    INDUSTRIAL_PRODUCTION: {
      hasIcon: false
    },
    AGRICULTURE: {
      hasIcon: false
    },
    CONSTRUCTION: {
      hasIcon: false
    },
  },
  INFRASTRUCTURE: {
    unit: '%',
    GAS_SUPPLY: {
      hasIcon: false
    },
    WATER_SUPPLY: {
      hasIcon: false
    },
    INTERNET_ACCESS: {
      hasIcon: false
    },
  },
  ADDITIONAL_STAT: {
    unit: '%',
    ADDITIONAL_SUB_STAT: {
      hasIcon: false
    }
  }
}

const getYearVal = (val: string) => val === 'currentYearVal' ? '2024' : '2023';

const BasicStats: FC<IProps> = ({ basicStats, additionalStats }) => {
  const { t } = useTranslation();

  return (
    <BasicStatsContainer>
      {
        Object.keys(basicStats).length > 0
          ? <>
            {
              Object.keys(basicStats)?.map((key) =>
                <BasicStatsItem key={key}>
                  <div className="title">
                    <Text color='#202224' fontWeight={700} fontSize='0.75rem'>{t(`new-fields.${key}`)}</Text>
                    <span>{properties[key].unit}</span>
                  </div>
                  <div className={`body ${['ROAD', 'FOOD'].includes(key) && 'with-icon'}`}>
                    {
                      basicStats[key].map((stat: any, index: number) => (
                        <React.Fragment key={index}>
                          {
                            properties[key][stat.subType].hasIcon
                              ? <div className='body__item with-icon' key={properties[key][stat.subType].toString() + index}>
                                <div className="icon">{properties[key][stat.subType].icon}</div>
                                <Text className='percent-value' fontSize='1.875rem' color='#118F84' lineHeight='1' fontWeight={700}>
                                  {stat.currentYearVal} {properties[key].unit}
                                </Text>
                                <div className="increase">
                                  <Text fontSize='0.75rem' color='#98A3A9' lineHeight='1' fontWeight={700}>{t('new-fields.прирост')}</Text>
                                  <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? '#4ABE51' : '#C57979'} lineHeight='1' fontWeight={700}>
                                    {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                    {stat.increaseVal}%
                                  </Text>
                                </div>
                              </div>
                              : <div className='body__item' key={properties[key][stat.subType].toString() + index}>
                                <Text className='subType' fontSize='0.75rem' color='#202224' lineHeight='1' fontWeight={400}>
                                  {t(`new-fields.${stat.subType}`)}
                                </Text>
                                <div className="increase">
                                  <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? '#4ABE51' : '#C57979'} lineHeight='1' fontWeight={600}>
                                    {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                    {stat.increaseVal}%
                                  </Text>
                                </div>
                                <Text className='currentYearVal' fontSize='1rem' color='#118F84' lineHeight='1' fontWeight={700}>{stat.currentYearVal}</Text>
                              </div>
                          }
                        </React.Fragment>
                      ))
                    }
                  </div>
                </BasicStatsItem>
              )
            }
            {
              Object.keys(additionalStats).length > 0
                ? <>
                  {
                    Object.keys(additionalStats).map((key) =>
                      <BasicStatsItem key={key}>
                        <div className="title">
                          <Text color='#202224' fontWeight={700} fontSize='0.75rem'>{key}</Text>
                          <span>%</span>
                        </div>
                        <div className="body">
                          {
                            additionalStats[key].map((stat: any, index: number) => (
                              <div className='body__item' key={index}>
                                <Text className='subType' fontSize='0.75rem' color='#98A3A9' lineHeight='1' fontWeight={700}>
                                  {stat.additionalInfo[`subtype${getLang()}`]}
                                </Text>
                                <div className="increase">
                                  <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? '#4ABE51' : '#C57979'} lineHeight='1' fontWeight={600}>
                                    {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                                    {stat.increaseVal}%
                                  </Text>
                                </div>
                                <Text className='currentYearVal' fontSize='1rem' color='#118F84' lineHeight='1' fontWeight={700}>{stat.currentYearVal}</Text>
                              </div>
                            ))
                          }
                        </div>
                      </BasicStatsItem>
                    )
                  }
                </>
                : null
            }
          </>
          : <>
            {t('no-data')}
          </>
      }
    </BasicStatsContainer>
  )
}

export default BasicStats;
