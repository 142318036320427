
import i18n from "../i18n";


const formatDate = (date: string) => {
  if (date) {
    return new Date(date).toLocaleDateString('ru').replaceAll('.', '-') || '';
  } else {
    return '';
  }
}

const t = (key: string, lng: string = 'ru', options?: any) => i18n.getResource(lng.toLowerCase(), 'translation', key, options) || key;

const getLang = () => {
  const { language } = i18n;
  return language === 'kz' ? 'Kz' : 'Ru';
}

const capitalize = (str: string) => str?.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

const keepCapitalized = (str: string) => str?.split(' ').map(str => str.charAt(0) + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

function lowerAndTrim(inputString: string) {
  if (inputString) {
    const words = inputString.split(' ');

    const processWord = (word: string) => {
      let capitalCount = 0;
      let modifiedWord = '';

      for (let i = 0; i < word.length; i++) {
        if (word[i] === word[i].toUpperCase()) {
          capitalCount++;

          if (capitalCount <= 4) {
            modifiedWord += word[i];
          } else {
            modifiedWord += word[i].toLowerCase();
          }
        } else {
          modifiedWord += word[i].toLowerCase();
        }
      }

      return modifiedWord;
    };

    const processedWords = words.map(processWord);

    const result = processedWords.join(' ').replace(/  +/g, ' ');

    return result;
  }

  return '';
}

const formatNumber = (number: string) => number?.toString().split('').filter((item: string) => +item >= 1).join('') || '0'

const checkVal = (val: any) => val !== null && val !== undefined && val !== '' && +val !== 0

const isRegion = (kato: number) => kato.toString()[5] === '0' && kato.toString()[6] === '0'

export {
  formatDate,
  t,
  getLang,
  capitalize,
  lowerAndTrim,
  keepCapitalized,
  formatNumber,
  checkVal,
  isRegion
}