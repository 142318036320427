import React, { useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import BasicLayout from '../layout/basic.layout';
import Home from './home/home.page.';
import AdminPage from './admin/admin.page';
import Snp from './admin/snp-admin.page';
import Analytics from './admin/analytics-admin.page';
import Sections from './admin/sections-admin.page';
import SelectedSnp from './admin/selected-snp-admin.page';
import Infrastructure from '../components/insfrastructureProjects/infrastructure.component';
import Reports from '../components/insfrastructureProjects/reports.component';
import EducationForm from '../components/insfrastructureProjects/education-form.component';
import HealthForm from '../components/insfrastructureProjects/health-form.component';
import CultureSportForm from '../components/insfrastructureProjects/culture-sport-form.component';
import WaterSupplyForm from '../components/insfrastructureProjects/water-supply-form.component';
import GasSupplyForm from '../components/insfrastructureProjects/gas-supply-form.component';
import InternetForm from '../components/insfrastructureProjects/internet-form.component';
import AccessRoadForm from '../components/insfrastructureProjects/access-road-form.component';
import VillageRoadForm from '../components/insfrastructureProjects/village-road-form.component';
import LightingForm from '../components/insfrastructureProjects/lighting-form.component';
import AgricultureProducersForm from '../components/admin-page/agriculture-producers-form.component';
import VillageImprovementForm from '../components/admin-page/village-improvement-form.component';
import InvestmentForm from '../components/admin-page/investment-form.component';
import i18n from '../i18n';

const   RoutesIndex = () => {
  const [params] = useSearchParams()

  useEffect(() => {
    if (params.get('lang')) {
      i18n.changeLanguage(params.get('lang') as 'kz' | 'ru')
    }
  }, [params])

  return (
    <Routes>
      <Route path='/' element={<BasicLayout />}>
        <Route index element={<Home />} />
        <Route path='/:kato' element={<Home />} />
        <Route path='/:kato/:link' element={<Home />} />
        <Route path='/:link' element={<Home />} />
      </Route>
      <Route path='/admin' element={<AdminPage />} >
        <Route path='snp' element={<Snp />} />
        <Route path='analytics' element={<Analytics />} />
        <Route path='sections' element={<Sections />} />
        <Route path=':kato' element={<SelectedSnp />}>
          <Route path='infrastructure' element={<Infrastructure />}>
            <Route path=':type' element={<Reports />} />
          </Route>
          <Route path='infrastructure/education/:objectId' element={<EducationForm />} />
          <Route path='infrastructure/health/:objectId' element={<HealthForm />} />
          <Route path='infrastructure/culture-sport/:objectId' element={<CultureSportForm />} />
          <Route path='infrastructure/water-supply/:objectId' element={<WaterSupplyForm />} />
          <Route path='infrastructure/gas-supply/:objectId' element={<GasSupplyForm />} />
          <Route path='infrastructure/internet-communication/:objectId' element={<InternetForm />} />
          <Route path='infrastructure/access-road/:objectId' element={<AccessRoadForm />} />
          <Route path='infrastructure/village-road/:objectId' element={<VillageRoadForm />} />
          <Route path='infrastructure/lighting/:objectId' element={<LightingForm />} />
          <Route path=':type' element={<Reports />} />
          <Route path='snp_agriculture_producers/:objectId' element={<AgricultureProducersForm />} />
          <Route path='snp_improvement/:objectId' element={<VillageImprovementForm />} />
          <Route path='snp_investment/:objectId' element={<InvestmentForm />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default RoutesIndex