import styled, { css } from "styled-components";
import { Container, ModalContent } from "../index.style";
import ArrowRight from '../../assets/icons/arrow-right.svg'

const Heading = styled.div`
  width: 100%;
  padding: 0.625rem 1.25rem;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  text-transform: uppercase;
`;

const Info = styled.div`
  display: flex;
  gap: 0.625rem;
  height: 100%;

  .content{
    flex: 1;
  }
`;

const NavBar = styled(Container)`
  flex-direction: column;
  gap: 0.625rem;
  width: 10.5rem;
  height: 100%;
`;

const NavItem = styled.div<{ selected: boolean }>`
  width: 9.25rem;
  border-radius: 3.125rem;
  border: 1px solid #DBDCE0;
  padding: 0.375rem 0.9375rem;
  cursor: pointer;
  background: #fff;

  p {
    max-width: 7rem;
    white-space: break-all;
    font-size: 0.75rem;
    color: #606367;
  }

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    position: relative;
    
    p {
      color: #fff;
      font-weight: 700;
    }
    
    &:after{
      content: "";
      background-image: url(${ArrowRight});
      background-size: 0.25rem 0.5rem;
      position: absolute;
      right: 0.9375rem;
      width: 0.25rem;
      height: 0.5rem;
      top: 50%;
      transform: translateY(-50%);  
    }
  `}
`;

const FloatingTabsContainer = styled.div`
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  padding: 0.625rem;
  border-radius: 10px;
  background: #F5F5F5;
  display: flex;
  gap: 0.625rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  position: relative;
  z-index: 100000;
  height: fit-content;
`;

const TabButton = styled.button<{ selected: boolean }>`
  border-radius: 30px;
  border: 1px solid #DBDCE0;
  padding: 0px 0.9375rem;
  cursor: pointer;
  height: 1.6875rem;
  text-transform: uppercase;
  font-size: 0.75rem;

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    color: #FFFFFF;
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 2.9375rem;
  display: flex;
  gap: 0.625rem;
`;

const Item = styled(Container) <{ full?: boolean }>`
  gap: 0.625rem;

  ${({ full }) => full && css`
    flex: 1;
  `}

  img {
    cursor: pointer;
  }
`;

const RightMenuContainer = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  border-radius: 0px 10px 10px 0px;
  background: #FFF;
  flex: 1;
  margin-left: -10px;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem; 
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  z-index: 5;
  position: absolute;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 2rem);
  left: 23rem;
  width: calc(100vw - 23rem);
  margin: 0.625rem 0.625rem 0.625rem -1.25rem;

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.625rem;
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  
  ${ModalContent}{
    left: 60%;
  }

  iframe {
    height: 100%;
  }

  .close-button{
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 0.625rem;

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9;
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }  
`;

const EducationItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #DBDCE0;
  width: 100%;

  .heading,
  .subheading {
    color: #118F84;
    font-size: 14px;
    width: 100%;
  }

  .with-icon{
    display: flex;
    gap: 0.625rem;
    align-items: center;
    cursor: pointer;

    path {
      stroke: #118F84;
    }
  }

  .heading{
    font-weight: 700;
    padding: 0px 0.625rem 0.625rem 0.625rem;
    border-radius: 10px;
    border-bottom: 2px solid #DBDCE0;
    text-transform: uppercase;
  }

  .items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.625rem;

    .item{
      flex: 0.5;
      align-self: flex-start;
    }
  }

  .item {
    padding: 0.625rem;
    border-radius: 0.625rem;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    justify-self: stretch;
   
    > div {
      display: flex;
      gap: 0.3125rem;
      font-size: 0.875rem;
    }

    b {
      font-size: 0.875rem;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #DBDCE0;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    border-radius: 0.625rem;
    border-bottom: 1px solid #DBDCE0;
    padding: 0.625rem;

    ul {
      padding-left: 1.25rem;

      li{
        color: #606367;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.375rem;
        b {
          font-size: 14px;
        }
      }
    }
  }

  .subheading{
    padding-bottom: 0.375rem;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #DBDCE0;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }

  .text {
    font-size: 14px;
    color: #606367;
  }

  .images {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    img{
      max-width: 14.375rem;
      max-height: 8.1875rem;
      border-radius: 0.625rem;
      cursor: pointer;
    }

    .footer{ 
      display: flex;
      justify-content: space-between;
      width: 100%;

      button { 
        all: unset;
        cursor: pointer;
      }
    }
  }

  .images__item{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-end;
  }

  .images__date{
    font-size: 0.75rem;
    font-style: italic;
  }

  .memorandum-year {
    padding-top: 0.625rem;
  }

  .street-list-heading{
    color: #118F84;
    line-height: 1;
  }
`;

const ZoomableImageContainer = styled.div`
  position: relative;
  max-width: 60vw; 
  width: 60vw; 
    
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  align-items: center;

  .react-transform-wrapper, {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .react-transform-component{
      width: 90%;
      height: auto;
      margin: 0 auto;
    }
  }


  .tools {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    z-index: 100;
  }

  button{
    background: #fff;
    border: 1px solid #DBDCE0;
    padding: 0.3125rem 0.625rem;
    cursor: pointer;

    svg{
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  img {
    height: auto; 
    margin: 0 auto;
    max-height: 70vh;
  }
 
`;

const AnalyticsWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  max-width: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  
  .container{
    display: flex;
    flex-direction: column;
    background: #DBDCE0;
    border-radius: 0.625rem;
    height: 12.5rem;
    .title {
      width: 100%;
      border-radius: 0.625rem;
      border: 1px solid #DBDCE0;
      background: #FFF;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.4375rem 0.625rem;
      min-height: 1.75rem;
      white-space: wrap;
      max-width: 20rem;
      // flex: 1;
    }

    .chart-container {
      max-height: 10.75rem;
      width: 20rem;
      background: #FFF;
      border-radius: 0.625rem;
      border: 1px solid #DBDCE0;
      padding: 0.625rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .doughnut-total {
        position: absolute;
        font-size: 1.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .bar-total {
        position: absolute;
        font-size: 0.75rem;
        right: 0.5rem;
        top: 0;
      }
    }
  }

  .half {
    height: fit-content;
    .chart-container {
      height: 4.1875rem;
    }
  }

  .white {
    background: #FFF;
    gap: 0.625rem;
  }

  .labels { 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    align-items: flex-start;
    justify-content: flex-start;  

    .label {
      display: flex;
      gap: 0.625rem;
      justify-content: flex-start;
      align-items: center;
    }
    
    .text {
      font-size: 0.625rem;  
      text-align: left;
    }

    .box{
      width: 0.5rem;
      height: 0.5rem;
    }
  }
`;

const AgricultureWrapper = styled.div`
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  .list-heading{
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #118F84;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    cursor: pointer;
    path {
      stroke: #118F84;
    }
  }
`

const PassportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  
  .item {
    padding: 0.625rem;
    border-radius: 0.625rem;
    background: #FFF;
    border: 1px solid #DBDCE0;
    display: flex;
    gap: 0.625rem;
  };

  .container {
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;
    background: #FFF;

    &.supervisor{
      background: #F5F5F5;
    }

    &.police .item {
      border: 0;
    }
  }

  .photo {
    width: 6.25rem;
    height: 8.31369rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;  
    cursor: pointer;
  };

  .info {
    width: 100%;
  }

  .name {
    color: #118F84;
    font-family: Segoe UI;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .group {
    gap: 0.625rem; 

    &.gray {
      display: flex;
      padding: 0.625rem;
      flex-direction: column;
      gap: 0.375rem;
      border-radius: 0.625rem;
      background: #F5F5F5;
      max-width: 50%;
      margin-top: 0.625rem;
    }
  }

  .position {
    color: #606367;
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .row {
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  .works-from {
    padding: 0.125rem 0.3125rem;
    border-radius: 0.3125rem;
    background: #F5F5F5;
    color: #606367;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .date { 
    color: #606367;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #DBDCE0;
    margin: 0.94rem 0;
  }

  .phone {
    display: flex;
    gap: 0.625rem;
    img {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .number {
    font-size: 0.875rem;
    font-weight: 700;
    color: #2366CA;
    text-decoration: underline;
  }

  .other {
    color: #606367;
    font-size: 0.75rem;
    font-weight: 400;
  }

  .photo-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.625rem;
  }

  .download-button {
    all: unset;
    cursor: pointer;
  }

 

`;

const SnpInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .container {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;
    &.yellow {
      background: linear-gradient(0deg, #FDFBF2 0%, #FDFBF2 100%), #F5F5F5;
    }
  }

  .row {
    width: 100%;
    display: flex;
    gap: 1.25rem;
  }
`;

const SnpInfoItemContainer = styled.div<{ type: string, icon?: string, lang?: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.625rem;
    border: 2px solid #DBDCE0;

    .header{
      display: flex;
      gap: 0.625rem;
      align-items: center;
      padding: 0.625rem;
      justify-content: center;
      width: 100%;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      background: #FFF;
      text-align: center;

      .title {
        color: #118F84;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
      }

      svg {
        position: absolute;
        left: -0.625rem;
        transform: translateX(-100%);
      }
    }

    .body { 
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      padding: 0.625rem;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }

    .subtitle {
      color: #606367;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: center;
    }

    ${({ type }) => type === 'population' && css`
      .value {
        color: #606367;
        font-size: 1.25rem;
        font-weight: 500;
      }
    `}

    ${({ type, lang }) => type === 'employment' && lang === 'kz' && css`

    .value {
      color: #606367;
      font-size: 1.25rem;
      font-weight: 500;
    }

    @media (max-width: 1450px){
      &:first-child,
      &:nth-child(2) {
          .header svg {
            transform: translateX(0%);
            left: 0.625rem;
          }
      }
    }
    `}


    ${({ type }) => type === 'road' && css`
      .header{
        background: linear-gradient(0deg, #FDFBF2 0%, #FDFBF2 100%), #F5F5F5;
      }

      .value {
        color: #118F84;
        font-size: 2.1875rem;
        font-weight: 600;
       }
    `}
`;

const BasicStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  flex: 0.33;
  max-width: 100%;
`;

const BasicStatsItem = styled.div`
  background: #DBDCE0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 1px;
  border-radius: 0.625rem;

  .title {
    display: flex;
    justify-content: space-between;
    background: #FFF;
    padding: 0.5rem 0.625rem;
    border-radius: 0.625rem;
  }

  .body {
    background: #F8F7EF;
    display: flex;
    padding: 0 0.625rem;
    flex-direction: column;
    border-radius: 0.625rem;

    &__item {
      display: flex;
      gap: 0.625rem;
      padding: 0.625rem 0;
      border-bottom: 1px solid #DBDCE0; 
      align-items: center;

      .subType {
        flex: 1;
      }

      .currentYearVal {
        min-width: 55px;
        white-space: nowrap;
        text-align: right;

        &.plan {
          border: 1px solid #DBDCE0;
          text-align: center;
          border-radius: 0.3125rem;
          height: 21px;
          padding: 0 0.25rem;
        }

        &.fact {
          text-align: center;
          border-radius: 0.3125rem;
          height: 21px;
          padding: 0 0.25rem;
          background: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .name {
        flex: 1;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        flex-direction: column;

        div:last-child {
          display: flex;
          align-items: center;
          gap: 0.3125rem;
        }
      }
      
      &.with-icon {
        align-items: center;
        gap: 1.25rem;
        justify-content: center;
        padding: 0.625rem;
        width: 100%;
        margin: 0;

        .percent-value {
          max-width: 115px;
          min-width: 87px;
          text-align: center;
          white-space: nowrap;
          margin: 0 0.3125rem;
        }

        &:first-child {
          border-radius: 0.625rem 0.625rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.625rem 0.625rem;
        }

        .icon {
          width: 23px;
          height: 23px;
          background: #FFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          svg {
            width: 100%;
          }
        }
      }
    }

    &.with-icon {
      padding: 0;
    }
  }

  .headers {
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    
    div {
      width: 55px;
      text-align: center;
    }
  }
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .search-bar {
    flex: 1;
    display: flex;
    gap: 0.625rem;
    align-items: center;
    
    input {
      width: 100%;
      border: 1px solid #DBDCE0;
      border-radius: 0.3125rem;
      height: 1.5rem;
      border: 1px solid #DBDCE0
      &:focus,
      &:active,
      &:focus-visible {
        outline: 0;
      }
    }
  }

  .dates {
    display: flex;
    align-items: center;
    gap: 0.3125rem;

    input {
      outline: 0;
      border: 0;
      border-radius: 0.3125rem;
      background: #F5F5F5;
      display: flex;
      align-items: center;
    }
  }
`;


export {
  Heading,
  Info,
  NavBar,
  NavItem,
  FloatingTabsContainer,
  TabButton,
  HeaderContainer,
  Item,
  RightMenuContainer,
  EducationContainer,
  EducationItem,
  ZoomableImageContainer,
  AnalyticsWrapper,
  AgricultureWrapper,
  PassportWrapper,
  SnpInfoWrapper,
  SnpInfoItemContainer,
  BasicStatsContainer,
  BasicStatsItem,
  Filters,
}
