import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import RegionItem from '../../components/snp-page/region-item.component'
import { ISettlement, ISettlementsObject, ISnp } from '../../interfaces/snp.interface'
import { useTranslation } from 'react-i18next'
import { getRegionList, getSettlementTree, getStatistics } from '../../requests/kato.request'
import { getLang } from '../../utils/helpers.utils'
import { getFilteredTree } from '../../requests/supervisor.request'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 85vh;
  overflow-y: auto;
  padding-right: 0.625rem;

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9;
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }
`

const citiesNamesList = ['Рудный Г.А.', 'Арқалық Қ.Ә.', 'Рудный Қ.Ә.', 'Аркалык Г.А.']

const SnpPage = () => {
  const [searchText, setSearchText] = useState('');
  const [searchParams] = useSearchParams();
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const { i18n: { language } } = useTranslation();

  const navigate = useNavigate();

  const getOptions = () => {
    getFilteredTree()
      .then((res: any) => {
        const list = res.list.sort((a: any, b: any) => a[`name${getLang()}`].localeCompare(b[`name${getLang()}`]));
        setListOptions(list);
        setFilteredOptions(list);
      })
  }

  const handleClick = (item: any) => {
    navigate(`/admin/${item.kato}`, { state: { item } });
  }

  useEffect(() => {
    getOptions()
  }, [language])


  useEffect(() => {
    setSearchText(searchParams.get('searchText') || '');
  }, [searchParams])

  useEffect(() => {
    const snpInfo = JSON.parse(localStorage.getItem('user') || '');
    if (snpInfo && snpInfo.kato_access) {
      setKatoAccessList(snpInfo.kato_access)
    }
  }, [])

  useEffect(() => {
    if (listOptions) {
      const filteredData = listOptions.map((region) => {
        const matchingDistricts = region.children.filter((district: any) =>
          district[`name${getLang()}`].toLowerCase().includes(searchText.toLowerCase())
        );

        if (matchingDistricts.length > 0) {
          return {
            ...region,
            children: matchingDistricts,
          };
        }

        return null;
      }).filter(Boolean);

      setFilteredOptions(filteredData);
    }
  }, [listOptions, searchText])


  return (
    <Wrapper>
      {
        filteredOptions && filteredOptions?.length > 0 && filteredOptions?.map((item) =>
          <RegionItem
            key={item.kato}
            region={item}
            onClick={handleClick}
            isEditable={katoAccessList.includes(+item.kato) || katoAccessList.includes(0)}
          />
        )
      }
    </Wrapper>
  )
}

export default SnpPage