import React, { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BasicStatsContainer, BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IPopulationDynamic } from '../../interfaces/snp.interface';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

interface IProps {
  chartInfo: {
    data: any;
    options: any;
  };
}

const PopulationDynamic: FC<IProps> = ({ chartInfo }) => {
  const { t } = useTranslation();

  return <BasicStatsContainer>
    <BasicStatsItem  >
      <div className="title">
        <Text color='#202224' fontWeight={700} fontSize='0.75rem'>{t(`population-dynamic.title`)}</Text>
        <span>{t(`population-dynamic.people`)}</span>
      </div>
      <div className="body">
        {chartInfo && <Line data={chartInfo.data} options={chartInfo.options} plugins={[ChartDataLabels]} />}
      </div>
    </BasicStatsItem >
  </BasicStatsContainer >
};

export default PopulationDynamic;
