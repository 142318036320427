import React, { ChangeEvent, useEffect, useState } from 'react'
import { Field } from 'formik'
import { AddField } from '../../assets/icons';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles'
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { updateObject } from '../../requests/supervisor.request';
import { documentationStatusOptions } from '../../constants/snp.constant';
import { lowerAndTrim, t } from '../../utils/helpers.utils';

interface IProps {
  formData: any;
  setFormData: (formData: any, ...rest: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  type: string;
  onSave: (data: any) => void;
  errors?: any,
  streetId?: number,
}

const DocumentationForm: React.FC<IProps> = (props) => {
  const { formData, setFormData, setFieldValue, lang, type, onSave, errors, streetId = null } = props

  const [isAddingFund, setIsAddingFund] = useState<boolean>(false);
  const [newFund, setNewFund] = useState<{ label: string, value: string }>({ label: '', value: '' });
  const [base, setBase] = useState<string | null>(null);
  const [data, setData] = useState<any>(formData);

  const handleAdd = () => {
    if (!newFund.label || !newFund.value) return;
    const val = data.requiredFund && data.requiredFund?.[lang.toLowerCase()]
      ? { ...data.requiredFund[lang.toLowerCase()], [newFund.label]: +newFund.value }
      : { [newFund.label]: +newFund.value }

    const field = {
      ...data.requiredFund,
      [lang.toLowerCase()]: {
        ...val
      },
    }

    const body = streetId !== null
      ? {
        ...formData,
        streetList: formData.streetList.map((item: any) => item.id === streetId ? { ...item, requiredFund: field } : item)
      }
      : {
        ...formData,
        requiredFund: field
      }

    setFormData(body)

    onSave(body)

    setNewFund({ label: '', value: '' })
    setIsAddingFund(false)
  }

  const handleDelete = (key: string) => {
    const { [key]: deleted, ...rest } = data.requiredFund[lang.toLowerCase()];
    const updated = {
      ...data.requiredFund,
      [lang.toLowerCase()]: rest
    }

    const body = streetId !== null
      ? {
        ...formData,
        streetList: formData.streetList.map((item: any) => item.id === streetId ? { ...item, requiredFund: updated } : item)
      }
      : {
        ...formData,
        requiredFund: updated
      }

    setFormData(body)
    updateObject(type, body)
  }

  const handleCancelAdd = () => {
    setNewFund({ label: '', value: '' })
    setIsAddingFund(false)
  }

  const handleValueChange = (key: string, val: any, setFieldValue: (fieldName: string, value: string) => void) => {
    if (streetId !== null) {
      setFieldValue(`${base}${key}`, val)
      setFormData({
        ...formData,
        streetList: formData.streetList.map((item: any) => item.id === streetId ? { ...item, [key]: val } : item)
      })
    } else {
      setFieldValue(key, val);
      setFormData({ ...formData, [key]: val });
    }
  }

  useEffect(() => {
    if (streetId !== null) {
      setBase(`streets.${streetId}.`)
      setData(formData.streetList.find((item: any) => item.id === streetId))
    } else {
      setBase('')
      setData(formData)
    }

  }, [streetId, formData])

  if (base === null) {
    return <></>
  }

  return (
    <>
      <FormBlock>
        <div className="row sb wrap">
          <FormGroup>
            <label className="required" htmlFor={`${base}documentationStatus`}>{t(`form.documentationStatus`, lang)}</label>
            <Field
              id={`${base}documentationStatus`}
              name={`${base}documentationStatus`}
              as={'select'}
              className={errors['documentationStatus'] ? 'error' : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleValueChange('documentationStatus', e.target.value, setFieldValue)}
              value={data.documentationStatus}
            >
              <option value={""} selected></option>
              {documentationStatusOptions.map((option) => <option value={option.value} key={option.value}>{t(`form.documentationStatusObj.${option.value}`, lang)}</option>)}
            </Field>
          </FormGroup>
        </div>

        <div className="row sb wrap">
          <FormGroup>
            <label className="required" htmlFor={`${base}workStart`}>{t(`form.workStart`, lang)}</label>
            <Field
              id={`${base}workStart`}
              name={`${base}workStart`}
              placeholder={t(`form.workStart`, lang)}
              as="input"
              type="date"
              value={data.workStart ? data.workStart.split('T')[0] : null}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workStart', e.target.value, setFieldValue)}
              max={data.workEnd}
              className={errors['date'] ? 'error' : ''}
            />
          </FormGroup>
        </div>
        <div className="row sb wrap">
          <FormGroup>
            <label className="required" htmlFor={`${base}workEnd`}>{t(`form.workEnd`, lang)}</label>
            <Field
              id={`${base}workEnd`}
              name={`${base}workEnd`}
              placeholder={t(`form.workEnd`, lang)}
              as="input"
              type="date"
              value={data.workEnd ? data.workEnd.split('T')[0] : null}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workEnd', e.target.value, setFieldValue)}
              min={data.workStart}
              className={errors['date'] ? 'error' : ''}
            />
          </FormGroup>
        </div>
        <div className="row sb wrap">
          <FormGroup>
            <label className='required' htmlFor={`${base}requiredFundSource${lang}`}>{t(`form.requiredFundSource`, lang)}</label>
            <Field
              id={`${base}requiredFundSource${lang}`}
              name={`${base}requiredFundSource${lang}`}
              placeholder={t(`form.requiredFundSource`, lang)}
              onChange={(e: any) => handleValueChange(`requiredFundSource${lang}`, lowerAndTrim(e.target.value), setFieldValue)}
              className={errors['requiredFundSource'] ? 'error' : ''}
              value={data[`requiredFundSource${lang}`]}
            />
          </FormGroup>
        </div>
        <div className="row sb wrap">
          <FormGroup>
            <label className='required' htmlFor={`${base}requiredFundAmount`}>{t(`form.requiredFundAmount`, lang)}</label>
            <Field
              id={`${base}requiredFundAmount`}
              name={`${base}requiredFundAmount`}
              placeholder={t(`form.requiredFundAmount`, lang)}
              as={'input'}
              type="number"
              className={errors['requiredFundAmount'] ? 'error' : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('requiredFundAmount', e.target.value, setFieldValue)}
              value={data.requiredFundAmount}
            />
          </FormGroup>
        </div>

        <label className="required" htmlFor="">{t('form.requiredFundSource', lang)}</label>

        {data.requiredFund && data.requiredFund[lang.toLowerCase()] && Object.keys(data.requiredFund[lang.toLowerCase()]).length > 0 && Object.keys(data.requiredFund[lang.toLowerCase()]).map((key) => {
          return (
            <div className="row sb" key={key}>
              <FormGroup>
                {!key.includes('no-label') && <label htmlFor={`requiredFund.${lang.toLowerCase()}.${key}`}>{key}</label>}
                <Field
                  id={`requiredFund.${lang.toLowerCase()}.${key}`}
                  name={`requiredFund.${lang.toLowerCase()}.${key}`}
                  value={data.requiredFund[lang.toLowerCase()][key]}
                  onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`requiredFund.${lang.toLowerCase()}.${key}`, e.target.value)
                      setFormData({ ...formData, requiredFund: { ...formData.requiredFund, [lang.toLowerCase()]: { ...formData.requiredFund[lang.toLowerCase()], [key]: e.target.value.replace(' ', '') } } })
                    }}
                />
              </FormGroup>
              <FooterButton variant='delete' onClick={() => handleDelete(key)}>x</FooterButton>
            </div>
          )
        })}


        {isAddingFund && <div className="row sb">
          <FormGroup>
            <input type="text"
              value={newFund.label}
              onChange={(e) => setNewFund({ ...newFund, label: lowerAndTrim(e.target.value) })}
              placeholder={t('source', lang)}
            />
            <input type="text"
              value={newFund.value}
              onChange={(e) => setNewFund({ ...newFund, value: e.target.value.replace(' ', '') })}
              placeholder={t('sum', lang)}
            />
          </FormGroup>
        </div>}

        <div className="add-info__buttons">
          {isAddingFund
            ? <>
              <FormBlockButton onClick={handleAdd}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setIsAddingFund(true)}>
              <AddField /> {t('form.add_fund', lang)}
            </FormBlockButton>
          }
        </div >

      </FormBlock>

    </>
  )
}

export default DocumentationForm