import React, { FC } from 'react'
import { IDistrictIncomeList } from '../../interfaces/snp.interface'
import { BasicStatsContainer, BasicStatsItem } from './index.styles'
import { Text } from '../text.component'
import { useTranslation } from 'react-i18next'
import { IncomeIcon, TrendDown, TrendUp } from '../../assets/icons'

interface IProps {
  data: IDistrictIncomeList
}

const DistrictIncome: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <BasicStatsContainer>
      <BasicStatsItem >
        <div className="title">
          <Text color='#202224' fontWeight={700} fontSize='0.75rem'>{t(`district-income.title`)}</Text>
          <span>млн. тг</span>
        </div>
        <div className="body">
          <div className='body__item with-icon'>
            <div className="icon"><IncomeIcon /></div>
            <Text className='percent-value' fontSize='1.875rem' color='#118F84' lineHeight='1' fontWeight={700}>
              {data.currentYearIncomeShareVal}
            </Text>
            <div className="increase">
              <Text fontSize='0.75rem' color='#98A3A9' lineHeight='1' fontWeight={700}>{t('new-fields.прирост')}</Text>
              <Text fontSize='0.75rem' color={data.totalIncomeIncrease >= 0 ? '#4ABE51' : '#C57979'} lineHeight='1' fontWeight={700}>
                {data.totalIncomeIncrease >= 0 ? <TrendUp /> : <TrendDown />}
                {data.totalIncomeIncrease}%
              </Text>
            </div>
          </div>
          <div className="headers">
            <Text fontSize='0.75rem' color='#98A3A9' lineHeight='1'>План</Text>
            <Text fontSize='0.75rem' color='#98A3A9' lineHeight='1'>Факт </Text>
          </div>
          {data.districtIncomeStatList.map((stat, index) => (
            <div className='body__item' style={{ paddingTop: index === 0 ? '5px' : '10px' }} key={index}>
              <Text className='subType' fontSize='0.75rem' color='#202224' lineHeight='1' fontWeight={400}>
                {t(`district-income.${stat.type}-full`)}
              </Text>
              <Text className='currentYearVal plan' fontSize='1rem' color='#197CA4' lineHeight='1' fontWeight={400}>{stat.currentYearPlanVal}</Text>
              <Text className='currentYearVal fact' fontSize='1.25rem' color='#118F84' lineHeight='1' fontWeight={700}>{stat.currentYearFactVal}</Text>
            </div>
          ))}
        </div>
      </BasicStatsItem>
    </BasicStatsContainer>
  )
}

export default DistrictIncome