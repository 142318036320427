import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock } from './infrastructure.styles';

import { IProject } from '../../interfaces/snp.interface';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { options } from '../../constants/snp.constant';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils'
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import { errMsg, skipList as defaultSkipList } from './health-form.component';
import { documentationKeys } from './education-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "nameKz": "",
  "nameRu": "",
  "nameEn": "",
  "constructionYear": 0,
  "roadLength": 0,
  "factInfo": "",
  "requirements": {
    "additionalProp1": {},
    "additionalProp2": {},
    "additionalProp3": {}
  },
  "documentationStatus": "",
  "requiredFundSource": "",
  "requiredFundAmount": 0,
  "workStart": "",
  "workEnd": ""
}

const skipList = [
  'factInfo',
  ...defaultSkipList,
]

const AccessRoadForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleSave = (values: IProject) => {
    setErrors({});
    const data: any = {
      ...values,
      additionalFields: { ...values.additionalFields, ...formData.additionalFields },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (data.repairRequired && (data.repairRequired === 'true' || data.repairRequired === true)) {
      if (!checkVal(data.documentationStatus)) {
        setErrors({ ...errors, documentationStatus: true })
        toast.error(t(`errors.documentationStatus`, language))
        return
      } else if (data.documentationStatus === 'HAVE') {
        for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
          if (!checkVal(data[key])) {
            setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
            toast.error(t(`errors.${errMsg[key]}`, language))
            return
          }
        }
      }
    }

    if (formData.files && formData.files.length === 0) {
      setErrors((prev: any) => ({ ...prev, files: true }))
      toast.error(t(`errors.files`, language))
      return
    }

    updateObject('access-road', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('access-road', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('access-road', val).then(res => {
        navigate(`/admin/${val}/infrastructure/access-road/${res.id}`);
      })
    }
  }, [kato, navigate, objectId])

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormGroup>
        <label className='required' htmlFor={`name${lang}`}>{t(`form.roadName`, lang)}</label>
        <Field
          id={`name${lang}`}
          name={`name${lang}`}
          placeholder={t(`form.roadName`, lang)}
          onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
          className={errors['objectName'] ? 'error' : ''}
        />
      </FormGroup>

      <FormBlock>
        <div className="grid">
          <FormGroup>
            <label className='required' htmlFor={`constructionYear`}>{t(`form.constructionYear`, lang)}</label>
            <Field
              id={`constructionYear`}
              name={`constructionYear`}
              placeholder={t(`form.constructionYear`, lang)}
              as="input"
              type="number"
              className={errors['constructionYear'] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label className='required' htmlFor={`roadLength`}>{t(`form.roadLength`, lang)}</label>
            <Field
              id={`roadLength`}
              name={`roadLength`}
              placeholder={t(`form.roadLength`, lang)}
              as="input"
              type="number"
              className={errors['roadLength'] ? 'error' : ''}
            />
          </FormGroup>
        </div>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
          <select
            value={formData.factCondition}
            onChange={(e) => {
              setFieldValue(`factCondition`, e.target.value);
              setFormData({ ...formData, factCondition: e.target.value })
            }}
            className={errors[`factCondition`] ? 'error' : ''}
          >
            <option value="" selected hidden></option>
            {
              options.map((option) => (
                <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
              ))
            }
          </select>
        </FormGroup>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`repairRequired`}>{t(`requirementsOptions.required`, lang)}</label>
          <select
            name="repairRequired"
            id="repairRequired"
            onChange={(e: any) => {
              setFieldValue(`repairRequired`, e.target.value);
              setFormData({ ...formData, repairRequired: e.target.value })
            }}
            defaultValue={""}
            value={formData.repairRequired}
            className={errors[`repair-required`] ? 'error' : ''}
          >
            <option value="" hidden></option>
            {['true', 'false'].map((option) => (
              <option value={option}>{t(`${option}`, lang)} </option>
            ))}
          </select>
        </FormGroup>
      </FormBlock>

      {
        (formData.repairRequired === 'true' || formData.repairRequired === true) && (
          <DocumentationForm
            formData={formData}
            setFormData={setFormData}
            lang={lang}
            setFieldValue={setFieldValue}
            type='access-road'
            onSave={handleSave}
            errors={errors}
          />
        )
      }

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        type='access-road'
        onSave={handleSave}
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='access-road' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'access-road'}
        images={images}
        lang={language}
      />

    </Wrapper >
  )
}

export default AccessRoadForm