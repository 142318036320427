import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LeftMenu from '../../components/admin-page/left-menu.component';
import { Outlet } from 'react-router-dom';
import Header from '../../components/admin-page/header.component';
import TestVersion from '../../components/test-version.component';

const Wrapper = styled.div`
  background: #F2EFDE;
  display: flex;
  align-items: flex-start;
  height: 100vh;

  .right {
    padding: 0.625rem 0.625rem 0 0.625rem;
    width: 100%;
    height: 100%;
    max-width: 84vw;
  }

  .absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
  };

  .language {
    display: flex;
    position: absolute;
    top: 0.625rem;
    right: 0;
    gap: 0.625rem;
    align-items: center;
    font-size: 0.875rem;
    color: black;
    flex-direction: column;
    background: #fff;
    padding: 0.625rem;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;

    > div {
      cursor: pointer;
      width: 100%;
    }

    .selected {
      font-weight: 700;
    }
  }
`

const Content = styled.div`
  background: #fff;
  height: 100%;
  width: 100%;
  padding: 1.25rem;
  border-radius: 0.625rem 0.625rem 0 0;
`

const AdminPage = () => {
  const [isTest, setIsTest] = useState(false);

  useEffect(() => {
    window.location.hostname.includes('auyl-aktobe.kz') ? setIsTest(false) : setIsTest(true);
  }, [])

  return (
    <Wrapper>
      {/* {isTest && <div className='absolute'><TestVersion /></div>} */}
      <LeftMenu />
      <div className="right">
        <Content>
          <Header />
          <Outlet />
        </Content>
      </div>
    </Wrapper>
  )
}

export default AdminPage