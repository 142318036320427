import axios from 'axios';
import { getToken } from './auth.request';
import { getAccessToken, getRefreshToken, removeTokens, setTokens } from './token.request';

let refreshTokenPromise: Promise<any> | null;
const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
      config.headers!.credentials = 'include';
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();
    if ([401, 403].includes(error.response?.status)) {
      if (refreshToken) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getToken(refreshToken)
            .then((data) => {
              refreshTokenPromise = null;
              return data;
            })
            .catch(() => {
              removeTokens();
              window.location.href = `/`;
              return Promise.reject(error);
            });
        }

        return refreshTokenPromise.then((res) => {
          if (res.error && [401, 403].includes(error.response?.status)) {
            localStorage.removeItem('user');
            removeTokens();
            window.location.href = `/`;
            return Promise.reject(error);
          }

          const { access, refresh } = res;
          setTokens(access, refresh);
          api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
          return api(originalRequest);
        });
      } else {
        localStorage.removeItem('user');
        removeTokens();
        window.location.href = `/`;
      }
    }
    return Promise.reject(error);
  },
);

export default api;
