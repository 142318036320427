import styled, { css } from "styled-components";

const RegionWrapper = styled.div<{ isOpen: boolean, lg: string }>`
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  width: 100%;
  padding: 0.625rem;
  border-radius: 5px;
  border: 1px solid #DBDCE0;
  background: #F5F5F5;
  &:nth-child(2n) {
    background: #FFFFFF;
  }

  .name {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    cursor: pointer;

    span {
      color: #202224;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .primary {
      width: 20%;
    }

    .secondary {
      color: #606367;
      font-size: 0.75rem;
    }

    .first {
      min-width: ${({ lg }) => lg === 'kz' ? '7.75rem' : '5.5rem'}
    }

    .second {
      min-width: ${({ lg }) => lg === 'kz' ? '5rem' : '5.75rem'}
    }

    .third {
      min-width: ${({ lg }) => lg === 'kz' ? '6.5rem' : '6.5rem'}
    }

    .fourth {
      min-width: ${({ lg }) => lg === 'kz' ? '4.5rem' : '5.5rem'}
    }
  }

  .districts {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  ${({ isOpen }) => isOpen && css`
    .name {
      padding-bottom: 0.625rem;
      border-bottom: 1px solid #DBDCE0;
      color: #202224;
      font-size: 0.875rem;
      font-weight: 600;
      cursor: pointer;
       svg:not(.pen) {
        transform: rotate(180deg);
      }
    }
  `}
`;

const DistrictWrapper = styled.div<{ isOpen: boolean, status?: string }>`
  .district-name {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    cursor: pointer;

    span {
      color: #202224;
      font-size: 0.875rem;
    }
  }

  .snps {
    display: flex;
    flex-direction: column;
    gap: 0.3175rem;
    padding-left: 1.5rem;
    padding-top: 0.625rem;
    svg path {
      stroke: #98A3A9;
    }

    svg.pen path {
      stroke: #017F3B;
    }
  }

  ${({ isOpen }) => isOpen && css`
    .district-name {
      > svg {
        transform: rotate(180deg);
      }
    }
  `}

  ${({ status }) => status === 'IN_PROCESS' && css`
    span {
      background: rgba(255, 255, 0, 0.4); 
    }
  `}

  ${({ status }) => status === 'COMPLETED' && css`
    span {
      background: rgba(0, 255, 0, 0.4); 
    }
  `}
`
const SnpName = styled.span<{ status?: string }>`
  cursor: pointer;
  position: relative;
  font-size: 0.875rem;
  color: #202224;
  z-index: 2;

  ${({ status }) => status === 'IN_PROCESS' && css`
    background: rgba(255, 255, 0, 0.4); 
  `}

  ${({ status }) => status === 'COMPLETED' && css`
    background: rgba(0, 255, 0, 0.4); 
  `}
`

export {
  RegionWrapper,
  DistrictWrapper,
  SnpName
}