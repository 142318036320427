import React, { ChangeEvent, useState } from 'react'
import { Field } from 'formik';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { updateObject } from '../../requests/supervisor.request';
import { AddField } from '../../assets/icons';
import { lowerAndTrim, t } from '../../utils/helpers.utils'

interface IProps {
  formData: any;
  setFormData: (formData: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  type: string;
  onSave: (data: any) => void;
}

const AdditionalFieldsForm: React.FC<IProps> = ({ formData, setFormData, setFieldValue, lang, type, onSave }) => {

  const [isAddingAdditionalField, setIsAddingAdditionalField] = useState(false);
  const [newAdditionalField, setNewAdditionalField] = useState<{ label: string, value: string }>({ label: '', value: '' });
  const [isAddingText, setIsAddingText] = useState(false);
  const [newText, setNewText] = useState('');

  const handleAdd = () => {
    if (!newAdditionalField.label || !newAdditionalField.value) return;
    const val = formData.additionalFields && formData.additionalFields?.[lang.toLowerCase()]
      ? { ...formData.additionalFields[lang.toLowerCase()], [newAdditionalField.label]: newAdditionalField.value }
      : { [newAdditionalField.label]: newAdditionalField.value }

    const field = {
      ...formData.additionalFields,
      [lang.toLowerCase()]: {
        ...val
      },
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setNewAdditionalField({ label: '', value: '' })
    setIsAddingAdditionalField(false)
  }

  const handleAddText = () => {
    if (newText.trim().length === 0) return;
    const val = formData.additionalFields && formData.additionalFields?.[lang.toLowerCase()]
      ? { ...formData.additionalFields[lang.toLowerCase()], [`no-label-${+new Date()}`]: newText }
      : { [`no-label-${+new Date()}`]: newText }

    const field = {
      ...formData.additionalFields,
      [lang.toLowerCase()]: { ...val }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    onSave({
      ...formData,
      additionalFields: field
    })

    setNewText('')
    setIsAddingText(false)
  }

  const handleDelete = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields[lang.toLowerCase()];
    const updated = {
      ...formData.additionalFields,
      [lang.toLowerCase()]: rest
    }
    setFormData({ ...formData, additionalFields: updated })
    updateObject(type, { ...formData, additionalFields: updated })
  }

  const handleCancelAdd = () => {
    setNewAdditionalField({ label: '', value: '' });
    setIsAddingAdditionalField(false);
    setNewText('');
    setIsAddingText(false);
  }

  return (
    <FormBlock>
      <div className="title">{t(`form.additionalFields`, lang)}</div>

      {formData.additionalFields && formData.additionalFields[lang.toLowerCase()] && Object.keys(formData.additionalFields[lang.toLowerCase()]).length > 0 && Object.keys(formData.additionalFields[lang.toLowerCase()]).map((key) => {
        return (
          <div className="row sb" key={key}>
            <FormGroup>
              {!key.includes('no-label') && <label htmlFor={`additionalFields.${lang.toLowerCase()}.${key}`}>{key}</label>}
              <Field
                id={`additionalFields.${lang.toLowerCase()}.${key}`}
                name={`additionalFields.${lang.toLowerCase()}.${key}`}
                value={formData.additionalFields[lang.toLowerCase()][key]}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(`additionalFields.${lang.toLowerCase()}.${key}`, lowerAndTrim(e.target.value))
                    setFormData({ ...formData, additionalFields: { ...formData.additionalFields, [lang.toLowerCase()]: { ...formData.additionalFields[lang.toLowerCase()], [key]: lowerAndTrim(e.target.value) } } })
                  }}
              />
            </FormGroup>
            <FooterButton variant='delete' onClick={() => handleDelete(key)}>x</FooterButton>
          </div>
        )
      })}

      {isAddingAdditionalField && <div className="row sb">
        <FormGroup>
          <input type="text"
            value={newAdditionalField.label}
            onChange={(e) => setNewAdditionalField({ ...newAdditionalField, label: lowerAndTrim(e.target.value) })}
          />
          <input type="text"
            value={newAdditionalField.value}
            onChange={(e) => setNewAdditionalField({ ...newAdditionalField, value: lowerAndTrim(e.target.value) })}
          />
        </FormGroup>
      </div>}

      {isAddingText && <div className="row sb">
        <FormGroup>
          <textarea
            value={newText}
            onChange={(e) => setNewText(lowerAndTrim(e.target.value))}
            rows={3}
          />
        </FormGroup>
      </div>}

      <div className="add-info__buttons">

        {!isAddingText && <>
          {isAddingAdditionalField
            ? <>
              <FormBlockButton onClick={handleAdd}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setIsAddingAdditionalField(true)}>
              <AddField /> {t('form.add_field', lang)}
            </FormBlockButton>
          }</>}

        {!isAddingAdditionalField && <>
          {isAddingText
            ? <>
              <FormBlockButton onClick={handleAddText}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setIsAddingText(true)}>
              <AddField /> {t('form.add_info', lang)}
            </FormBlockButton>
          }</>
        }

      </div>

    </FormBlock>
  )
}

export default AdditionalFieldsForm