import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../modal-component';
import { getImageUrl } from '../right-menu/education.component';

import {
  AddField,
  DeleteX,
  EditPen,
  Save,
  GoUp,
  Trash
} from '../../assets/icons';

import {
  Wrapper,
  FormBlock,
  FormGroup,
  Footer,
  FooterButton
} from './admin-page.styles';

import {
  IInvestmentProject,
  IPopulationDynamic,
  IProject,
  OfficialRoleType,
} from '../../interfaces/snp.interface';

import { getRegionList, getSettlementTree } from '../../requests/kato.request';
import { getGeneralInfo } from '../../requests/snp.request';
import {
  deleteImage,
  updateForm,
  uploadGeneralImage,
  changeStatus,
  deleteAll,

} from '../../requests/supervisor.request';

import { capitalize, checkVal, getLang, isRegion, keepCapitalized, t } from '../../utils/helpers.utils'
import ImageGrid from '../insfrastructureProjects/image-grid.component';
import { Text } from '../text.component';
import { FormBlockButton } from '../insfrastructureProjects/infrastructure.styles';

const initialFormData: any = {
};

const errMsg: any = {
  nameKz: 'fullName',
  nameRu: 'fullName',
  positionKz: 'position',
  positionRu: 'position',
  imageId: 'photo'
}

const skipList = ['kato', 'akim', 'imageIds', 'status', 'childInfoList', 'populationDynamic'];

const investProjectFields = [
  {
    name: 'totalSum',
    type: 'number',
  },
  {
    name: 'currentSum',
    type: 'number',
  },
  {
    name: 'completionPercent',
    type: 'number',
  },
]

const DATA = [
  {
    nameRu: '1',
    nameKz: '2',
    totalSum: 910,
    currentSum: 100,
  },
  {
    nameRu: '11',
    nameKz: '22',
    totalSum: 768,
    currentSum: 231,
  },
  {
    nameRu: '111',
    nameKz: '222',
    totalSum: 123,
    currentSum: 100,
  },
]

const GeneralInfo = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedRole, setSelectedRole] = useState<OfficialRoleType | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [formData, setFormData] = useState<any>(initialFormData);
  const [isFinished, setIsFinished] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [photoIds, setPhotoIds] = useState<any>({
    p: null,
    s: null,
  });
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [groupedStats, setGroupedStats] = useState<any>({});
  const [groupedAdditionalStats, setGroupedAdditionalStats] = useState<any>({});
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);

  const [newStat, setNewStat] = useState<any>({
    typeRu: '',
    typeKz: '',
    subtypes: [
      {
        subtypeRu: '',
        subtypeKz: '',
        fieldRu: '',
        fieldKz: '',
        currentYearVal: '',
        previousYearVal: ''
      }
    ]
  });
  const [isAddingNew, setIsAddingNew] = useState(false);

  const [newInvestProject, setNewInvestProject] = useState<IInvestmentProject>({
    nameRu: '',
    nameKz: '',
    totalSum: 0,
    currentSum: 0,
    completionPercent: 0
  })
  const [isAddingNewInvestProject, setIsAddingNewInvestProject] = useState(false);

  const currentYear = new Date().getFullYear();

  const years = [currentYear - 1, currentYear]
  const getYearVal = (year: number) => year === currentYear ? 'currentYearVal' : 'previousYearVal'

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);

    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const validateForm = (values: IProject) => {
    setErrors({});
    const valuesToSubmit: any = {
      ...values,
      akim: {
        ...values.akim,
        nameKz: capitalize(values.akim.nameKz) || null,
        nameRu: capitalize(values.akim.nameRu) || null,
        positionKz: keepCapitalized(values.akim.positionKz) || null,
        positionRu: keepCapitalized(values.akim.positionRu) || null,
        imageId: formData.akim.imageId || values.akim.imageId || null
      },
      basicStats: formData.basicStats
    }

    const keys = Object.keys(valuesToSubmit).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(valuesToSubmit[key])) {
        const foundKey = errMsg[key] || key
        setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
        toast.error(t(`errors.${foundKey}`, language));
        return false;
      }
    }

    const akim_keys = Object.keys(valuesToSubmit.akim).filter(item => item !== 'imageId')
    for (const key of akim_keys) {
      if (!checkVal(valuesToSubmit.akim[key])) {
        const foundKey = errMsg[key] || key
        setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
        toast.error(t(`errors.${foundKey}`, language));
        return false;
      }
    }

    for (const stat of valuesToSubmit.basicStats) {
      if (!stat.type.includes('ADDITIONAL_STAT') && (!checkVal(stat.currentYearVal) || !checkVal(stat.previousYearVal))) {
        setErrors((prev: any) => ({ ...prev, [`${stat.type}-${stat.subType}`]: true }))
        toast.error(t(`errors.${stat.type}-${stat.subType}`, language));
        return false;
      }
    };

    if (kato && isRegion(+kato)) {
      const districtIncomeList = (valuesToSubmit.districtIncomeStats.districtIncomeStatList)

      for (const item of districtIncomeList) {
        const keys = Object.keys(item).filter(key => !['id', 'type'].includes(key))
        for (const key of keys) {
          if (!checkVal(item[key])) {
            setErrors((prev: any) => ({ ...prev, [`${item.type}-${key}`]: true }))
            toast.error(t(`district-income.error.${item.type}-${key}`, language));
            return false;
          }
        }
      }
    }

    for (const item of valuesToSubmit.populationIncreaseDynamicStats) {
      if (!checkVal(item.value)) {
        setErrors((prev: any) => ({ ...prev, [`population-dynamic-${item.year}`]: true }))
        toast.error(t(`population-dynamic.error`, language).replace('{{ year }}', item.year));
        return false;
      }
    }
    return true;
  }

  const handleSubmitForm = (values: IProject) => {
    setErrors({});
    const valuesToSubmit: any = {
      ...values,
      akim: {
        ...values.akim,
        nameKz: capitalize(values.akim.nameKz) || null,
        nameRu: capitalize(values.akim.nameRu) || null,
        positionKz: keepCapitalized(values.akim.positionKz) || null,
        positionRu: keepCapitalized(values.akim.positionRu) || null,
        imageId: formData.akim.imageId || values.akim.imageId || null,
      },
      basicStats: formData.basicStats,
      districtIncomeStats: {
        ...values.districtIncomeStats,
        totalIncomeIncrease: getTotalIncomeIncrease(),
        currentYearIncomeShareVal: getIncomeShare('current'),
        previousYearIncomeShareVal: getIncomeShare('previous'),
      }
    }

    const keys = Object.keys(valuesToSubmit).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(valuesToSubmit[key])) {
        const foundKey = errMsg[key] || key
        setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
        toast.error(t(`errors.${foundKey}`, language));
        return;
      }
    }

    const akim_keys = Object.keys(valuesToSubmit.akim).filter(item => item !== 'imageId')
    for (const key of akim_keys) {
      if (!checkVal(valuesToSubmit.akim[key])) {
        const foundKey = errMsg[key] || key
        setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
        toast.error(t(`errors.${foundKey}`, language));
        return;
      }
    }

    for (const stat of valuesToSubmit.basicStats) {
      if (!stat.type.includes('ADDITIONAL_STAT') && (!checkVal(stat.currentYearVal) || !checkVal(stat.previousYearVal))) {
        setErrors((prev: any) => ({ ...prev, [`${stat.type}-${stat.subType}`]: true }))
        toast.error(t(`errors.${stat.type}-${stat.subType}`, language));
        return;
      }
    };

    if (kato && isRegion(+kato)) {
      const districtIncomeList = (valuesToSubmit.districtIncomeStats.districtIncomeStatList)

      for (const item of districtIncomeList) {
        const keys = Object.keys(item).filter(key => !['id', 'type'].includes(key))
        for (const key of keys) {
          if (!checkVal(item[key])) {
            setErrors((prev: any) => ({ ...prev, [`${item.type}-${key}`]: true }))
            toast.error(t(`district-income.error.${item.type}-${key}`, language));
            return;
          }
        }
      }
    }

    for (const item of valuesToSubmit.populationIncreaseDynamicStats) {
      if (!checkVal(item.value)) {
        setErrors((prev: any) => ({ ...prev, [`population-dynamic-${item.year}`]: true }))
        const errKey = item.year <= currentYear ? '' : '-predict';
        toast.error(t(`population-dynamic.error${errKey}`, language).replace('{{ year }}', item.year));
        return;
      }
    }

    updateForm(valuesToSubmit)
      .then((res) => {
        toast.success(t('toast.save_success'))
        loadForm();
      })
      .catch((err) => toast.error(t('toast.save_error')));
  }

  const handleSelectRole = (role: OfficialRoleType) => {
    setSelectedRole(role);
    openPhotoModal()
  }

  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    kato && selectedRole && uploadGeneralImage(+kato, selectedRole, selectedImage)
      .then(_ => {
        loadForm();
      })

    closePhotoModal();
  };

  const handleDeleteImage = (imageId: number) => {
    deleteImage(imageId).then(_ => loadForm())
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedImageName(file.name || '');
      setSelectedImage(file);
    }
  };

  const handleDelete = () => {
    setIsConfirmOpen(true);
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (validateForm(formikRef.current.values)) {
        setIsFinished(true);
        changeStatus(snp, 'COMPLETED').then(res => {
          formikRef.current.setValues({ ...formikRef.current.values, status: 'COMPLETED' })
          handleSubmitForm({ ...formikRef.current.values, status: 'COMPLETED' })
        })
      }
    } else {
      setIsFinished(false);
      changeStatus(snp, 'IN_PROCESS').then(res => {
        formikRef.current.setValues({ ...formikRef.current.values, status: 'IN_PROCESS' })
        handleSubmitForm({ ...formikRef.current.values, status: 'IN_PROCESS' })
      })
    }
  }

  const openPhotoModal = () => {
    setIsOpen(true);
  };

  const closePhotoModal = () => {
    setIsOpen(false);
  };

  const confirmDelete = () => {
    deleteAll(snp).then(() => navigate('/admin/snp'))
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      const data = {
        ...res,
        date: res.date ? res.date.split('T')[0] : '',
        akim: {
          ...res.akim,
          inPositionFrom: res.akim.inPositionFrom ? res.akim.inPositionFrom.split('T')[0] : '',
        },
      }

      setPhotoIds({ p: data?.police?.photoId, s: data?.supervisor?.photoId });
      const status = res.status;
      setIsFinished(status === 'COMPLETED');

      // const d = {
      //   ...data,
      //   investmentProjects: DATA,
      // }

      setFormData(data);

      const grouped = data.basicStats.reduce((acc: any, stat: any) => {
        if (!acc[stat.type]) {
          acc[stat.type] = [];
        }
        acc[stat.type].push(stat);
        return acc;
      }, {});

      setGroupedStats(grouped)
      getAdditionalStatsGrouped(data.basicStats);

      setIsLoading(false);
    });
  }, [region, snp, kato])

  const handleAddStat = () => {

    const res = newStat.subtypes.map((item: any) => ({
      type: 'ADDITIONAL_STAT',
      subType: 'ADDITIONAL_SUB_STAT',
      currentYearVal: item.currentYearVal,
      previousYearVal: item.previousYearVal,
      increaseVal: null,
      additionalInfo: {
        typeKz: newStat.typeKz,
        typeRu: newStat.typeRu,
        subtypeKz: item.subtypeKz,
        subtypeRu: item.subtypeRu,
        fieldRu: item.fieldRu,
        fieldKz: item.fieldKz,
      }
    }))

    setFormData({
      ...formData,
      basicStats: [
        ...formData.basicStats,
        ...res
      ]
    })

    getAdditionalStatsGrouped([
      ...formData.basicStats,
      ...res
    ])

    setNewStat({
      typeRu: '',
      typeKz: '',
      subtypes: [
        {
          subtypeRu: '',
          subtypeKz: '',
          fieldRu: '',
          fieldKz: '',
          currentYearVal: '',
          previousYearVal: ''
        }
      ]
    })

    setIsAddingNew(false);
  }

  const handleAddInvestProject = () => {
    setFormData({
      ...formData,
      investmentProjects: [
        ...formData.investmentProjects,
        newInvestProject
      ]
    })

    setIsAddingNewInvestProject(false);
  }

  const findIndexByKey = (key: string) => {
    const [type, subType] = key.split('-');

    const index = formData.basicStats?.findIndex((item: any) => item.type === type && item.subType === subType);

    return index;
  }

  const addNewSubtype = () => {
    setNewStat({
      ...newStat,
      subtypes: [
        ...newStat.subtypes,
        {
          subtypeRu: '',
          subtypeKz: '',
          fieldRu: '',
          fieldKz: '',
          currentYearVal: '',
          previousYearVal: ''
        }
      ]
    })
  }

  const getAdditionalStatsGrouped = (data: any) => {
    const gpoupedAdditionals = data
      .filter((item: any) => item.type === 'ADDITIONAL_STAT')
      .reduce((acc: any, stat: any) => {
        if (!acc[stat.additionalInfo.typeRu]) {
          acc[stat.additionalInfo.typeRu] = [];
        }
        acc[stat.additionalInfo.typeRu].push(stat);
        return acc;
      }, {});

    setGroupedAdditionalStats(gpoupedAdditionals);
  }

  const handleGoUp = () => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (katoAccessList?.length && kato) {
      if (!katoAccessList.includes(+kato) && !katoAccessList.includes(0) && !katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4)))) {
        navigate('/admin/snp')
      }
      const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato ? kato : snpItem.kato;
      getSettlementTree().then((res: any) => {
        getRegionList().then((regionRes: any) => {
          const withChildren = (Object.keys(res.data)
            .map((item: any) => ({
              key: item, children: Object.keys(res.data[item])
                .map((key: string) => {
                  const { pnameKz, pnameRu, pkato } = res.data[item][key][0]
                  return ({ value: pkato, label: pnameRu, labelKz: pnameKz })
                })
            })))

          const regions = withChildren.map((elem: any) => ({ ...elem, ...regionRes.find((item: any) => [item.nameRu, item.nameKz].includes(elem.key)) }))
          const filtered = katoAccessList.includes(0)
            ? regions
              .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
            : regions
              .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
              .filter((item) => katoAccessList.some(kato => kato.toString().startsWith(item.kato.toString().substring(0, 4))))

          setRegions(filtered)

          const filteredSnps = katoAccessList.includes(0)
            ? filtered[0].children
            : filtered[0].children.filter((item: any) => katoAccessList.includes(+item.kato));

          setSnps(filteredSnps);

          if (val) {
            const found = regions.find((item: any) => item.children.find((item: any) => +item.value === +val)) || regions.find((item: any) => +item.kato === +val);
            if (found) {
              setRegion(+found.value || +found.kato);

              const filteredSnps = katoAccessList.includes(0)
                ? found.children
                : found.children.filter((item: any) => katoAccessList.includes(+item.value) || katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4))));
              setSnps(filteredSnps);
              setSnp(+val);
            }
          }
        })
      });
    }
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    const snpInfo = JSON.parse(localStorage.getItem('user') || '');
    if (snpInfo && snpInfo.kato_access) {
      setKatoAccessList(snpInfo.kato_access)
    }
  }, [])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const getAdditionalStatByTypes = (stat: any) => {
    return formData.basicStats.findIndex((item: any) => item.additionalInfo?.typeRu === stat.additionalInfo?.typeRu && item.additionalInfo?.subtypeRu === stat.additionalInfo?.subtypeRu)
  }

  const handleDeleteAdditional = (key: any) => {
    setFormData({
      ...formData,
      basicStats: formData.basicStats.filter((item: any, i: number) => item.additionalInfo?.typeRu !== key)
    })

    getAdditionalStatsGrouped(formData.basicStats.filter((item: any, i: number) => item.additionalInfo?.typeRu !== key))
  }

  const getIncreaseVal = (stat: any) => {
    const item = formData.basicStats.find((item: any) => item.type === stat.type && item.subType === stat.subType);

    const current = item.currentYearVal ? +item.currentYearVal : 0;
    const previous = item.previousYearVal ? +item.previousYearVal : 0;
    if (current === 0 || previous === 0) return 0;

    return item.type === 'SER' ? (((current - previous) / previous) * 100).toFixed(1) : current - previous;
  }

  const getIncomeShare = (type: 'previous' | 'current') => {
    const values = formikRef.current?.values?.districtIncomeStats?.districtIncomeStatList;
    if (values) {
      if (values.some((item: any) => item[`${type}YearFactVal`] === 0 || item[`${type}YearPlanVal`] === 0)) return 0;

      const factSum = values.reduce((acc: number, item: any) => acc + item[`${type}YearFactVal`], 0);
      const planSum = values.reduce((acc: number, item: any) => acc + item[`${type}YearPlanVal`], 0);

      const res = factSum === 0 || planSum === 0 ? 0 : ((factSum / planSum) * 100).toFixed(1);
      return res
    }
  }

  const getTotalIncomeIncrease = () => {
    const values = {
      currentYearIncomeShareVal: getIncomeShare('current'),
      previousYearIncomeShareVal: getIncomeShare('previous'),
    };

    if (values) {
      const current = values.currentYearIncomeShareVal ? +values.currentYearIncomeShareVal : 0;
      const previous = values.previousYearIncomeShareVal ? +values.previousYearIncomeShareVal : 0;

      const res = current === 0 || previous === 0 ? 0 : ((current - previous)).toFixed(1);

      return res;
    }
  }

  const onlyLetters = (value: string) => value.replace(/[^\p{L}\s]/gu, '');

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: any) => {
    return (
      <div className="grid-item">
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="date">{t(`aktobe.date`, lang)}</label>
            <Field className={`${errors[`date`] ? 'error' : ''}`} name="date" as="input" type="date" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="population">{t(`aktobe.population`, lang)}</label>
            <Field className={`${errors[`population`] ? 'error' : ''}`} name="population" as="input" type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="populationDynamic">{t(`aktobe.population-dynamic`, lang)}</label>
            <Field
              className={`${errors[`populationDynamic`] ? 'error' : ''}`}
              name="populationDynamic"
              as="input"
              type="number"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="avgSalary">{t(`aktobe.avgSalary`, lang)}, ₸</label>
            <Field className={`${errors[`avgSalary`] ? 'error' : ''}`} name="avgSalary" as="input" type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="area">{t(`aktobe.area`, lang)}, га</label>
            <Field className={`${errors[`area`] ? 'error' : ''}`} name="area" as="input" type="number" />
          </div>
        </FormGroup>

        <FormBlock type='white'>
          <div className="child-grid" style={{ gridTemplateColumns: `repeat(${isRegion(formData.kato) ? '2' : '3'}, 1fr)` }}>
            <Text fontSize='0.75rem'>{t('aktobe.name')}</Text>
            {!isRegion(formData.kato) && <Text fontSize='0.75rem' ta="right">{isRegion(formData.kato) ? t('aktobe.toOblCenter') : t('aktobe.toCenter')}</Text>}
            <Text fontSize='0.75rem' ta={!isRegion(formData.kato) ? 'right' : 'left'}>{t('aktobe.population')}</Text>

            {
              formData.childInfoList.map((item: any, index: number) =>
                <React.Fragment key={index}>
                  <Text>{item[`name${lang}`]}</Text>
                  {!isRegion(formData.kato) && <FormGroup>
                    <Field
                      name={`childInfoList.${index}.distance`}
                    />
                  </FormGroup>}
                  <FormGroup>
                    <Field
                      name={`childInfoList.${index}.population`}
                    />
                  </FormGroup>
                </React.Fragment>
              )
            }
          </div>
        </FormBlock>

        <FormBlock type='gray' >
          <div className="title">{t(`aktobe.akim-title`, lang)}</div>
          <FormGroup>
            <label
              htmlFor={`akim.name${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.fullName`, lang)}
            </label>
            <Field
              name={`akim.name${lang}`}
              type="text"
              className={`capitalize ${errors[`fullName`] ? 'error' : ''}`}
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`akim.name${lang}`, capitalize(val))
                setFormData({ ...formData, akim: { ...formData.akim, [`name${lang}`]: capitalize(val) } })
              }}
            />
          </FormGroup>
          <FormGroup>
            <label
              htmlFor={`akim.position${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.position`, lang)}
            </label>
            <Field
              name={`akim.position${lang}`}
              type="text"
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`akim.position${lang}`, keepCapitalized(val))
              }}
              className={`${errors[`position`] ? 'error' : ''}`}
            />
          </FormGroup>
          <div className="row">
            <FormGroup>
              <label
                htmlFor={`akim.inPositionFrom`}
                className={'required'}
              >
                {t(`aktobe.akim.inPositionFrom`, lang)}
              </label>
              <Field
                name={`akim.inPositionFrom`}
                type="date"
                as='input'
                className={`${errors[`inPositionFrom`] ? 'error' : ''}`}
              />
            </FormGroup>
            <FormGroup>
              <label
                htmlFor={`akim.phone`}
                className={'required'}
              >
                {t(`aktobe.akim.phoneNumber`, lang)}
              </label>
              <Field
                name={`akim.phone`}
                as="input"
                type="number"
                className={`${errors[`phone`] ? 'error' : ''}`}
              />
            </FormGroup>
          </div>

          {
            lang.toLowerCase() === language && <FormGroup>
              <div className="photo">
                <label className={'required'} htmlFor={`akim.photoId`}>{t(`aktobe.akim.photoId`, lang)}</label>
                <div className={`image ${errors[`photoId`] ? 'error' : ''} `}>
                  {formData.akim.imageId
                    ? <>
                      <img src={getImageUrl(formData.akim.imageId)} alt="person" />
                      <div className="buttons">
                        <div className='edit' onClick={() => handleSelectRole('SUPERVISOR')}><EditPen /> {t('form.photo.edit', language)}</div>
                        <div className='delete' onClick={() => handleDeleteImage(+formData.akim.imageId)} ><DeleteX /> {t('form.photo.delete', language)}</div>
                      </div>
                    </>
                    : <div className="buttons">
                      <div className='upload' onClick={() => handleSelectRole('SUPERVISOR')}> <AddField /> {t('form.photo.upload', language)}</div>
                    </div>}
                </div>
              </div>
            </FormGroup>
          }
        </FormBlock>

        {
          formData.basicStats && Object.keys(groupedStats).map((type: any) => (
            type !== 'ADDITIONAL_STAT' && <FormBlock type="white" key={type} >
              <div className="title bold">{t(`new-fields.${type}`, lang)}</div>
              {
                groupedStats[type].map((stat: any) => (
                  <FormBlock type="white" key={stat.subType}>
                    {
                      stat.subType !== 'NONE' && <div className="title bold">
                        {t(`new-fields.${stat.subType}`, lang)}
                      </div>
                    }

                    {years.map((year) => (
                      <FormGroup key={year}>
                        <label
                          htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`)}].${getYearVal(year)}`}
                          className="required"
                        >
                          {t(`new-fields.${stat.type}-${stat.subType}`, lang)} {year}
                        </label>

                        <Field
                          name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`)}].${getYearVal(year)}`}
                          type="number"
                          as="input"
                          onChange={(e: any) => {
                            const val = stat.type === 'SER' ? e.target.value : Math.min(e.target.value, 100);

                            setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`)}].${getYearVal(year)}`, Math.max(0, val).toString());
                            setFormData({
                              ...formData,
                              basicStats: formData.basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`) ? {
                                ...item,
                                [getYearVal(year)]: Math.max(0, val).toString()
                              } : item)
                            })
                          }}
                          className={`${errors[`${stat.type}-${stat.subType}`]
                            ? 'error'
                            : ''
                            }`}
                        />
                      </FormGroup>
                    ))}
                    <FormGroup>
                      <label
                        htmlFor={`${stat.type}-${stat.subType}-increaseVal`}
                        className="required"
                      >
                        {t(`new-fields.${stat.type}-${stat.subType}-increaseVal`, lang)}
                      </label>

                      <Field
                        name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`)}]-increaseVal`}
                        type="number"
                        as="input"
                        value={getIncreaseVal(stat) !== null ? getIncreaseVal(stat) : formData.basicStats[findIndexByKey(`${stat.type}-${stat.subType}`)]?.increaseVal}
                        disabled
                      />
                    </FormGroup>
                  </FormBlock>
                ))
              }
            </FormBlock>
          ))
        }

        {
          groupedAdditionalStats && Object.keys(groupedAdditionalStats).map((key: any) => (
            <FormBlock type="white" key={groupedAdditionalStats[key].typeRu}>
              <div className="title bold">{groupedAdditionalStats[key][0].additionalInfo[`type${lang}`]}</div>
              <FooterButton style={{ width: 'max-content' }} variant='delete' type='button' onClick={() => handleDeleteAdditional(key)}>x</FooterButton>
              {
                groupedAdditionalStats[key].map((stat: any) => (
                  <FormBlock type="white" key={groupedAdditionalStats[key].subType}>
                    <div className="title bold">
                      {stat.additionalInfo[`subtype${lang}`]} { }
                    </div>
                    {years.map((year) => (
                      <FormGroup key={year}>
                        <label
                          htmlFor={`basicStats[${getAdditionalStatByTypes(stat)}].${getYearVal(year)}`}
                          className="required"
                        >
                          {stat.additionalInfo[`field${lang}`]} {year}
                        </label>

                        <Field
                          name={`basicStats[${getAdditionalStatByTypes(stat)}].${getYearVal(year)}`}
                          type="number"
                          as="input"
                          onChange={(e: any) => {
                            const val = e.target.value;
                            setFieldValue(`basicStats[${getAdditionalStatByTypes(stat)}].${getYearVal(year)}`, val.toString());
                            setFormData({
                              ...formData,
                              basicStats: formData.basicStats.map((item: any, i: number) => i === getAdditionalStatByTypes(stat) ? {
                                ...item,
                                [getYearVal(year)]: val.toString()
                              } : item)
                            })

                          }}
                          className={`${errors[`${stat.type}-${stat.subType}`]
                            ? 'error'
                            : ''
                            }`}
                          value={formData.basicStats[getAdditionalStatByTypes(stat)][getYearVal(year)] || stat[getYearVal(year)]}
                        />
                      </FormGroup>
                    ))}

                    <FormGroup>
                      <label
                        htmlFor={`basicStats[${getAdditionalStatByTypes(stat)}].increaseVal`}
                        className="required"
                      >
                        {t(`new-fields.increase`, lang)}
                      </label>
                      <Field
                        name={`basicStats[${getAdditionalStatByTypes(stat)}].increaseVal`}
                        type="number"
                        as="input"
                        disabled
                        value={getIncreaseVal(stat) !== null ? getIncreaseVal(stat) : formData.basicStats[getAdditionalStatByTypes(stat)]?.increaseVal}
                      />
                    </FormGroup>
                  </FormBlock>
                ))
              }

            </FormBlock>
          ))
        }

        {
          isAddingNew
          && <FormBlock type="white" >
            <FormGroup>
              <label className={'required'} htmlFor={`newStat.type${lang}`}>{t(`new-fields.type-name`, lang)}</label>

              <Field
                name={`newStat.type${lang}`}
                onChange={(e: any) => {
                  const val = e.target.value;
                  setNewStat({ ...newStat, [`type${lang}`]: val })
                }}
                className={`${errors[`new-fields-type${lang}`]
                  ? 'error'
                  : ''
                  }`}
              />
            </FormGroup>

            {newStat.subtypes && newStat.subtypes.map((subtype: any, index: number) => (
              <FormBlock type="white" >
                <FormGroup>
                  <label className={'required'} htmlFor={`newStat.subtype${lang}`}>{t(`new-fields.subtype-name`, lang)}</label>
                  <Field
                    name={`newStat.subtype${lang}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setNewStat({
                        ...newStat, subtypes: newStat.subtypes.map((item: any, i: number) => i === index ? {
                          ...item,
                          [`subtype${lang}`]: val
                        } : item)
                      })
                    }}
                    className={`${errors[`new-fields-subType${lang}`]
                      ? 'error'
                      : ''
                      }`}
                  />
                </FormGroup>

                <FormGroup>
                  <label className={'required'} htmlFor={`newStat.field${lang}`}>{t(`new-fields.field`, lang)}</label>
                  <Field
                    name={`newStat.field${lang}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setNewStat({
                        ...newStat, subtypes: newStat.subtypes.map((item: any, i: number) => i === index ? {
                          ...item,
                          [`field${lang}`]: val
                        } : item)
                      })
                    }}
                    className={`${errors[`new-fields-field${lang}`]
                      ? 'error'
                      : ''
                      }`}
                  />
                </FormGroup>

                {years.map((year) => (
                  <FormGroup key={year}>
                    <label className={'required'} htmlFor={`newStat[${index}].${getYearVal(year)}`}>{t(`new-fields.value`, lang)} {year}</label>
                    <Field
                      name={`newStat[${index}].${getYearVal(year)}`}
                      type="number"
                      as="input"
                      onChange={(e: any) => {
                        const val = e.target.value;
                        setNewStat({
                          ...newStat, subtypes: newStat.subtypes.map((item: any, i: number) => i === index ? {
                            ...item,
                            [getYearVal(year)]: Math.min(val, 100).toString()
                          } : item)
                        })

                        setFieldValue(`newStat[${index}].${getYearVal(year)}`, Math.min(val, 100).toString());
                      }}
                      className={errors[`newStat.${getYearVal(year)}`]
                        ? 'error'
                        : ''
                      }
                    />
                  </FormGroup>
                ))}
              </FormBlock>
            ))}

            <FormBlockButton onClick={addNewSubtype}>
              <AddField /> {t('new-fields.field', lang)}
            </FormBlockButton>
          </FormBlock>
        }

        {
          isAddingNew
            ? <>
              <FormBlockButton onClick={handleAddStat}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAddingNew(false)}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAddingNew(true)}>
              <AddField /> {t('form.add_field', lang)}
            </FormBlockButton>
        }

        {
          kato && isRegion(+kato) && formData.districtIncomeStats &&
          <FormBlock type='white'>
            <div className="title bold">{t(`district-income.title`, lang)}</div>
            {
              formData.districtIncomeStats?.districtIncomeStatList?.map((item: any, index: number) => (
                <FormBlock type='white' key={index}>
                  <div className="title bold">{t(`district-income.${item.type}`, lang)}</div>
                  {
                    Object.keys(item).map((objKey: string) => (!['id', 'type'].includes(objKey) &&
                      <FormGroup>
                        <label
                          htmlFor={`districtIncomeStats.districtIncomeStatList.${index}.${objKey}`}
                        >
                          {t(`district-income.${objKey}`, lang)}
                        </label>
                        <Field
                          name={`districtIncomeStats.districtIncomeStatList.${index}.${objKey}`}
                          type="number"
                          as="input"
                          className={`${errors[`${item.type}-${objKey}`] ? 'error' : ''}`}
                        />
                      </FormGroup>
                    ))
                  }
                </FormBlock>
              ))}

            <FormBlock type="white">
              <FormGroup>
                <label
                  htmlFor={'districtIncomeStats.currentYearIncomeShareVal'}
                  className="required"
                >
                  {t(`district-income.currentYearIncomeShareVal`, lang)}
                </label>
                <Field
                  name={'districtIncomeStats.currentYearIncomeShareVal'}
                  type="number"
                  as="input"
                  value={getIncomeShare('current')}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label
                  htmlFor={'districtIncomeStats.previousYearIncomeShareVal'}
                  className="required"
                >
                  {t(`district-income.previousYearIncomeShareVal`, lang)}
                </label>
                <Field
                  name={'districtIncomeStats.previousYearIncomeShareVal'}
                  type="number"
                  as="input"
                  value={getIncomeShare('previous')}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label
                  htmlFor={'districtIncomeStats.totalIncomeIncrease'}
                  className="required"
                >
                  {t(`district-income.totalIncomeIncrease`, lang)}
                </label>
                <Field
                  name={'districtIncomeStats.totalIncomeIncrease'}
                  type="number"
                  as="input"
                  disabled
                  value={getTotalIncomeIncrease()}
                />
              </FormGroup>
            </FormBlock>
          </FormBlock>
        }

        {
          formData.populationIncreaseDynamicStats &&
          <FormBlock type="white">
            <div className="title bold">{t('population-dynamic.title')}</div>
            {formData.populationIncreaseDynamicStats.map((item: IPopulationDynamic, index: number) => (
              <FormGroup type='row' key={index}>
                <label
                  htmlFor={`populationIncreaseDynamicStats[${index}].value`}
                  className="required"
                >
                  {item.year}
                </label>
                <Field
                  name={`populationIncreaseDynamicStats[${index}].value`}
                  type="number"
                  as="input"
                  className={`${errors[`population-dynamic-${item.year}`] ? 'error' : ''}`}
                  placeholder={t(`population-dynamic.placeholder${item.year > currentYear ? '-predict' : ''}`, lang).replace('{{ year }}', item.year)}
                  min={0}
                />
              </FormGroup>
            ))}
          </FormBlock>
        }

        {/* {
          formData?.investmentProjects &&
          <FormBlock type="white">
            <div className="title bold">{t('investmentProjects.title')}</div>

            {formData.investmentProjects.map((item: IInvestmentProject, index: number) => (
              <React.Fragment key={index}>
                <FormGroup >
                  <label
                    htmlFor={`investmentProjects[${index}].name${lang}`}
                    className="required"
                  >{t(`investmentProjects.name${lang}`)}
                  </label>
                  <Field
                    name={`investmentProjects[${index}].name${lang}`}
                    className={`${errors[`investmentProjects[${index}].name${lang}`] ? 'error' : ''}`}
                  />
                </FormGroup>
                {
                  investProjectFields.map((field, i) => (
                    <FormGroup>
                      <label
                        htmlFor={`investmentProjects[${index}].${field.name}`}
                        className="required"
                      >{t(`investmentProjects.${field.name}`)}</label>
                      {field.name === 'completionPercent'
                        ?
                        <Field
                          name={`investmentProjects[${index}].${field.name}`}
                          className={`${errors[`investmentProjects[${index}].name${lang}`] ? 'error' : ''}`}
                          type='number'
                          disabled
                          value={((item.currentSum / item.totalSum) * 100).toFixed(1) || 0}
                        />
                        :
                        <Field
                          name={`investmentProjects[${index}].${field.name}`}
                          className={`${errors[`investmentProjects[${index}].${field.name}`] ? 'error' : ''}`}
                          type='number'
                        />
                      }
                    </FormGroup>
                  ))
                }
              </React.Fragment>
            ))}
          </FormBlock>
        } */}

        {/* {
          isAddingNewInvestProject && <FormBlock type='white'>
            <React.Fragment >
              <FormGroup >
                <label
                  htmlFor={`newInvestProject.name${lang}`}
                  className="required"
                >{t(`investmentProjects.name${lang}`)}
                </label>
                <Field
                  name={`newInvestProject.name${lang}`}
                  className={`${errors[`newInvestProject.name${lang}`] ? 'error' : ''}`}
                />
              </FormGroup>
              {
                investProjectFields.map((field, i) => (
                  <FormGroup>
                    <label
                      htmlFor={`newInvestProject.${field.name}`}
                      className="required"
                    >{t(`investmentProjects.${field.name}`)}</label>
                    {field.name === 'completionPercent'
                      ?
                      <Field
                        name={`newInvestProject.${field.name}`}
                        className={`${errors[`newInvestProject.name${lang}`] ? 'error' : ''}`}
                        type='number'
                        disabled
                        value={((newInvestProject.currentSum / newInvestProject.totalSum) * 100).toFixed(1) || 0}
                      />
                      :
                      <Field
                        name={`newInvestProject.${field.name}`}
                        className={`${errors[`newInvestProject.${field.name}`] ? 'error' : ''}`}
                        type='number'
                      />
                    }
                  </FormGroup>
                ))
              }
            </React.Fragment>
          </FormBlock>
        }

        {
          isAddingNewInvestProject
            ? <>
              <FormBlockButton onClick={handleAddInvestProject}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={() => setIsAddingNewInvestProject(false)}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAddingNewInvestProject(true)}>
              <AddField /> {t('form.add_field', lang)}
            </FormBlockButton>
        } */}
      </div >
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={formData}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                      <div className='checkbox'>

                        <input type="checkbox" name="is-finished" id="is-finished" checked={isFinished} onChange={handleCheckboxChange} />
                        <label htmlFor="is-finished">{t(`${kato && isRegion(+kato) ? 'region' : 'so'}-is-finished`, language)}</label>
                      </div>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="delete" type='button' onClick={handleDelete}><Trash width={16} /> {t('delete-all', language)}</FooterButton>
                      <FooterButton variant="go-up" type='button' onClick={handleGoUp}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>

            <ImageGrid
              formData={formData}
              lang={language}
              type='general'
              images={formData.imageIds || []}
              loadForm={loadForm}
            />
          </Wrapper>

          <Modal isFileUpload isOpen={isOpen} onClose={closePhotoModal}>
            <label className="input-file">
              <span className="input-file-text">{selectedImageName}</span>
              <input type="file"
                accept="image/*"
                id="imageInput"
                onChange={handleImageSelect} />
              <span className="input-file-btn">{t('choose-file', language)}</span>
            </label>
            <button className="upload-button" onClick={handleImageUpload}>
              {t('form.photo.upload', language)}
            </button>
          </Modal>
          <Modal isFileUpload isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
            <h1>{t('confirm-delete-village', language)}</h1>
            <div className="buttons">
              <FooterButton variant="delete" type='button' onClick={confirmDelete}><Trash width={16} /> {t('form.requirementsObj.YES', language)}</FooterButton>
              <FooterButton variant="cancel" type='button' onClick={() => setIsConfirmOpen(false)}> {t('form.requirementsObj.NO', language)}</FooterButton>
            </div>
          </Modal>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default GeneralInfo