import React, { useEffect, useState } from 'react'
import LeftMenu from '../../components/left-menu/left-menu.component'
import RightMenu from '../../components/right-menu/right-menu.component'
import InteractiveMap from '../../components/map/map.component'
import styled from 'styled-components'
import { getLink, getMarkers, getRegionList, getSettlementTree } from '../../requests/kato.request'
import { kostanayDistricts, aktobeDistricts } from '../../constants/snp.constant'
import { useParams } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';

import { IMarker, IRegion } from '../../interfaces/snp.interface'
import TestVersion from '../../components/test-version.component'
import { getLang } from '../../utils/helpers.utils'
import MapPage from "../map";
import GoogleMapComponent from "../../components/google-map/google-map.component";

const HomeWrapper = styled.div`
  display: flex;
  height: 100vh;
  gap: 5rem;
  overflow: hidden;
  
  .absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  };
`
const Home = () => {
  const { kato } = useParams();

  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [regionList, setRegionList] = useState<IRegion[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const [selectedMarker, setSelectedMarker] = useState<IMarker | null>(null);
  const [infoLink, setInfoLink] = useState<string | null>(null)
  const [zoomedRegion, setZoomedRegion] = useState<any | null>(null);

  useEffect(() => {
    getRegionList().then((res) => {
      const list = res.filter((item) => !['Рудный Г.А.'].includes(item.nameRu))
        .map((item) => ({ ...aktobeDistricts.find(district => district.kato === item.kato), ...item, }));
      setRegionList(list);
      if (kato) {
        if (list.map(item => item.kato).includes(+kato)) {
          getMarkers(+kato).then((res) => {
            setMarkers(res);
          });
          setSelectedRegion(list.find(item => item.kato === +kato));
          setZoomedRegion(list.find(item => item.kato === +kato));
        } else {
          const foundRegionByMarker = list.find(item => item.kato.toString().slice(0, 4) === kato.slice(0, 4));
          if (foundRegionByMarker) {
            setSelectedRegion(foundRegionByMarker);
          }
          getMarkers(+kato).then((res) => {
            setMarkers(res);
          });
        }
      } else {
        getMarkers(0).then((res) => {
          setMarkers(res);
        });
      }
    });
  }, [kato]);

  return (
    <APIProvider apiKey={'AIzaSyBY8p3nROyRu1bh9hExu8uQXKsa_F_mIjI'} language={'ru'} libraries={['places']}>
      <HomeWrapper>
        <LeftMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedRegion={selectedRegion!}
          setSelectedRegion={setSelectedRegion}
          selectedMarker={selectedMarker!}
          setSelectedMarker={setSelectedMarker}
          markers={markers}
          setZoomedRegion={setZoomedRegion}
        />
        <RightMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedMarker={selectedMarker}
          infoLink={infoLink}
          selectedRegion={selectedRegion}
        />
        <GoogleMapComponent
          setSelectedRegion={setSelectedRegion}
          setSelectedMarker={setSelectedMarker}
        />

      </HomeWrapper>
    </APIProvider>
  )
}

export default Home